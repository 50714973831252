import { Typography, Stack, Box } from "@mui/material";
import { useWalletContext } from "./WalletContext";
import { SuccessTickIcon, WalletType } from "suregifts-component-lib";
import { useMemo, useState } from "react";
import { useGetWalletInfo, useGetFundingMethods, FundingMethodData } from "../../../services/transaction";

export const BalanceCard = () => {
  const { dispatch, state } = useWalletContext();
  const { data } = useGetWalletInfo();
  const { data: methods } = useGetFundingMethods();
  return (
    <div style={{ alignSelf: "center" }}>
      <Typography sx={{ fontSize: "0.625rem", color: "#AAAABE", textTransform: "uppercase" }}>Balance</Typography>
      <Typography sx={{ fontSize: "2.75rem", fontWeight: 700 }}>{data?.balanceString}</Typography>
      <Typography sx={{ fontSize: "0.625rem", color: "#AAAABE", textTransform: "uppercase" }}>top up wallet via:</Typography>
      <Stack spacing={"0.625rem"} direction="row" sx={{ marginTop: "0.9375rem" }}>
        {methods?.map((item) => (
          <FundButton key={item.id} method={item} onClick={() => dispatch({ type: "Fund", method: item })} />
        ))}
      </Stack>
    </div>
  );
};
export const usePaymentMethodImage = (methodId: WalletType) => {
  return useMemo(() => {
    switch (methodId) {
      case WalletType.BankTransfer:
        return "/assets/images/bank-transfer.png";
      case WalletType.Paypal:
        return "/assets/images/paypal.png";
      case WalletType.Crypto:
      case WalletType.FlutterwaveKenya:
      case WalletType.Paystack:
        return "/assets/images/card-payment.png";
      default:
        return "/assets/images/card-payment.png";
    }
  }, []);
};
type FundButtonProps = {
  method: FundingMethodData;
  selected?: boolean;
  onClick: () => void;
};
export const FundButton = ({ method, selected, onClick }: FundButtonProps) => {
  const icon = usePaymentMethodImage(method.id);
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        cursor: "pointer",
        borderRadius: "0.9375rem",
        padding: "1.125rem 0.9375rem",
        width: "100%",

        whiteSpace: "nowrap",
        overflow: "hidden",

        "&:hover": {
          backgroundColor: "primary.light",
        },
        ...(!!selected && {
          border: 1,
        }),
      }}
      onClick={onClick}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "1.25rem",
              width: "2.5rem",
              height: "2.5rem",
              backgroundColor: "primary.dark",
              overflow: "hidden",
            }}
          >
            <Box component={"img"} src={icon} />
          </Box>
          <span style={{ fontWeight: 600, textOverflow: "ellipsis" }}>{method.name}</span>
        </div>
        {typeof selected !== "undefined" && (
          <Box
            sx={{
              backgroundColor: "primary.dark",
              width: 30,
              height: 30,
              borderRadius: "15px",
              "& .MuiSvgIcon-root": {
                ...(!selected && {
                  display: "none",
                }),
              },
            }}
          >
            <SuccessTickIcon sx={{ color: "success.main", width: "100%", height: "100%" }} />
          </Box>
        )}
      </div>
    </Box>
  );
};
