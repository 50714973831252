import { Avatar, Box } from "@mui/material";
import React from "react";
import { useAuthContext } from "suregifts-component-lib";

export interface UserStatusViewProps {
  actionComponent?: React.ReactNode;
  size?: "small" | "medium";
}

export function UserStatusView({ actionComponent, size = "small" }: UserStatusViewProps) {
  const { user } = useAuthContext();

  return (
    <Box display="flex" alignItems="center" sx={{ overflow: "hidden", position: "relative" }}>
      <Avatar
        sx={{
          borderRadius: "1.125rem",
          width: size === "medium" ? "70px" : "60px",
          height: size === "medium" ? "70px" : "60px",
          flexGrow: 0,
        }}
        src={user?.photoUrl || "/assets/images/avatar.png"}
      >
        {`${user?.firstName.substring(0, 1)}${user?.lastName.substring(0, 1)}`}
      </Avatar>
      <Box display="flex" flexDirection="column" alignItems={"flex-start"} flexGrow={1} marginLeft={"1.0625rem"}>
        <Box sx={{ fontSize: "1.125rem", fontWeight: 500, marginBottom: "0.5rem" }}>{user?.firstName ? `${user.firstName} ${user.lastName}` : user?.email}</Box>
        {actionComponent}
      </Box>
    </Box>
  );
}

export default UserStatusView;
