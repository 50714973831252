import { Box, Container, IconButton, useTheme } from "@mui/material";
import { useCookies } from "react-cookie";
import CancelIcon from "@mui/icons-material/Cancel";
export const CookieNoticeView = () => {
  const theme = useTheme();
  const [cookies, setCookie] = useCookies(["GDPR_COOKIE"]);
  if (cookies.GDPR_COOKIE) return <></>;

  return (
    <Container sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
      <Box
        sx={{
          maxWidth: "md",
          backgroundColor: "primary.light",
          marginX: "auto",
          padding: "8px 16px",
          fontSize: "14px",
          color: "secondary.main",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>
          This website uses cookies. For further information on how we use cookies you can read our{" "}
          <a style={{ color: "#fa1c44", textDecoration: "none" }} href="/privacy-policy">
            Privacy and Cookie{" "}
          </a>{" "}
          notice
        </span>
        <IconButton
          color="secondary"
          sx={{ padding: 0 }}
          onClick={() => {
            setCookie("GDPR_COOKIE", 1, { path: "/" });
          }}
        >
          <CancelIcon sx={{}} />
        </IconButton>
      </Box>
    </Container>
  );
};
