import { Container } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorPageTemplate } from "suregifts-component-lib";
import { GlobalContextProvider } from "../contexts/GlobalContext";
import FooterSection from "./footer/FooterSection";

function ServerErrorPage() {
  const navigate = useNavigate();
  return (
    <GlobalContextProvider>
      <Container sx={{ marginBottom: "60px", marginTop: "2.5rem" }}>
        <ErrorPageTemplate
          imageUrl="/assets/images/server-error.svg"
          button1Label={"Reload Page"}
          button2Label={"Contact Us"}
          topText={"Page not working"}
          button1Click={() => window.history.back()}
          button2Click={() => navigate("/contact-support")}
          bottomText={<span>We are currently unable to handle your request</span>}
        />
      </Container>
      <FooterSection />
    </GlobalContextProvider>
  );
}

export default ServerErrorPage;
