import { createContext, useContext, useReducer } from "react";
import WalletScreen from "./WalletScreen";
import { TransactionData, FundingMethodData } from "../../../services/transaction";
import { useDocumentTitle } from "suregifts-component-lib";
export type ScreenContextValueProps = {
  state: ScreenState;
  dispatch: React.Dispatch<ActionProps>;
};
const WalletContext = createContext({} as ScreenContextValueProps);

export const useWalletContext = () => {
  return useContext(WalletContext);
};

type ScreenState = {
  type: "Main" | "View" | "Fund";
  transaction?: TransactionData;
  paymentMethod?: FundingMethodData;
};

type BaseActionProps = {
  type: "Main";
};

type ViewActionProps = {
  type: "View";
  transaction: TransactionData;
};

type FundActionProps = {
  type: "Fund";
  method: FundingMethodData;
};

type ActionProps = BaseActionProps | ViewActionProps | FundActionProps;

const initialState: ScreenState = { type: "Main" };

const reducer = (state: ScreenState, action: ActionProps): ScreenState => {
  switch (action.type) {
    case "Fund":
      return { type: "Fund", paymentMethod: action.method };
    case "View":
      return { type: "View", transaction: action.transaction };
    default:
      return { type: "Main" };
  }
};

export const WalletContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useDocumentTitle("Wallet History");
  return (
    <WalletContext.Provider value={{ state, dispatch }}>
      <WalletScreen />
    </WalletContext.Provider>
  );
};
