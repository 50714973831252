import { Box, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

export const CopyrightSection = () => {
  return (
    <Box
      sx={{
        marginTop: "3.125rem",
        display: "flex",
        flexWrap: "wrap",
        gap: "1.875rem",
        justifyContent: "space-between",
        color: "text.disabled",
        lineHeight: "1.25rem",
        "& a": {
          color: "text.disabled",
          textDecoration: "none",
        },
      }}
    >
      <Typography>© {`${moment().format("YYYY")}`} Suregifts. All Rights Reserved.</Typography>
      <Typography>
        <Link to="/terms-and-condition">Terms</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
      </Typography>
    </Box>
  );
};
