import { Box, useTheme } from "@mui/system";
import { useEffect } from "react";
import { useParams } from "react-router";
import { BackButton } from "../../../../components/atoms/BackButton";
import { useGetVoucherById } from "../../../../services/voucher";
import { VoucherInfoView } from "./VoucherInfoView";
import { LoadingBox } from "suregifts-component-lib";

function VoucherInfoScreen() {
  const { voucherCode } = useParams();
  const { data, isLoading, mutate: getVoucherInfo } = useGetVoucherById();

  useEffect(() => {
    getVoucherInfo(voucherCode as string);
  }, [voucherCode]);

  if (!data) {
    return <LoadingBox marginTop={"40px"} loading />;
  }
  return (
    <div style={{ position: "relative" }}>
      <BackButton to={"/vouchers"} />
      <Box
        sx={{
          borderRadius: "30px",
          backgroundColor: "primary.main",
          padding: "40px 50px",
        }}
      >
        <VoucherInfoView data={data} />
      </Box>
    </div>
  );
}

export default VoucherInfoScreen;
