import { TableHead, TableRow, TableCell } from "@mui/material";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { EditPencilIcon, DeleteIcon, Pagination, IOSSwitch, MainTable, MarginTop40Box, TransactionStatusLabel, IconView, TableBody } from "suregifts-component-lib";
import moment from "moment";
import { useWalletContext } from "./WalletContext";
import { useGetTransactions } from "../../../services/transaction";
function TableView({ filter, handleFilter }) {
  const { dispatch, state } = useWalletContext();
  const { data, isLoading, refetch } = useGetTransactions(filter);

  useEffect(() => {
    if (state.type === "Main") {
      refetch();
    }
  }, [state.type]);
  return (
    <MarginTop40Box>
      <MainTable loading={isLoading}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell sx={{ width: "40%" }}>Narration</TableCell>
            <TableCell>Transaction Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={`${item.transactionId}-${item.transactionTypeId}`}>
              <TableCell>{moment(item.date).format("DD.MM.YYYY")}</TableCell>
              <TableCell sx={{}}>{item.narration}</TableCell>
              <TableCell>{item.transactionMode}</TableCell>
              <TableCell>
                <TransactionStatusLabel text={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{item.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          align="center"
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </MarginTop40Box>
  );
}

export default TableView;
