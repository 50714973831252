import { Box, Card, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { MainTable, MarginTop40Box, OrderStatusLabel, PageFilter, Pagination, TabController, TableBody, dateTime, useDocumentTitle, useFilter } from "suregifts-component-lib";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useGetOrders } from "../../../services/order";
import { OrderNavSection } from "./OrderNavSection";

function MyOrderScreen() {
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "" });
  const { data: data, isFetching } = useGetOrders(filter);
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const navigate = useNavigate();
  useDocumentTitle("Order History");
  return (
    <Card
      sx={{
        borderRadius: "30px",
        backgroundColor: "primary.main",
        padding: "40px 50px",
      }}
    >
      <OrderNavSection total={data?.total} />
      <MainTable sx={{ marginTop: "40px" }} loading={isFetching}>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Order No</TableCell>
            <TableCell sx={{ textAlign: "center" }}>Quantity</TableCell>
            <TableCell>Payment method</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id} sx={{ cursor: "pointer" }} onClick={() => navigate(`${item.id}`)}>
              <TableCell>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{item.orderNumber}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>{item.quantity}</TableCell>
              <TableCell>{item.paymentMethod} </TableCell>
              <TableCell>{item.subTotal}</TableCell>
              <TableCell>{dateTime(item.dateCreated)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          align="center"
          size={filter.size}
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </Card>
  );
}

export default MyOrderScreen;
