export type NavItemProps = {
  label: string;
  to: string;
  children?: NavItemProps[];
  onClick?: (e) => void;
};
export const links: NavItemProps[] = [
  { label: "Account Overview", to: "/account" },
  { label: "My Orders", to: "/orders" },
  { label: "My Vouchers", to: "/vouchers" },
  { label: "Contacts", to: "/contacts" },
  { label: "Wallet", to: "/wallet" },
  { label: "Account Settings", to: "/account-settings" },
  {
    label: "Voucher Services",
    to: "/voucher-services",
    children: [
      { label: "Check Voucher Balance & History", to: "/voucher-services/check-balance" },
      { label: "Merge Voucher", to: "/voucher-services/merge" },
      { label: "Swap Voucher", to: "/voucher-services/swap" },
      { label: "Split Voucher", to: "/voucher-services/split" },

      { label: "Release Voucher", to: "/voucher-services/release" },
    ],
  },
  { label: "Support", to: "/contact-support" },
];
