import { Box, Typography, useTheme } from "@mui/material";
import { PressCard } from "suregifts-component-lib";

export const PressSection = () => {
  const theme = useTheme();
  return (
    <div style={{ marginTop: "6rem", textAlign: "center" }}>
      <Typography sx={{ fontSize: "5rem", fontWeight: "700", lineHeight: "120%" }}>Press</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          "& > div": {
            width: "22.5rem",
          },
          [theme.breakpoints.up(1066)]: {
            "& > div:hover": {
              position: "relative",
              bottom: "1rem",
            },
          },
          gap: "1.25rem",
          marginTop: "3.75rem",
        }}
      >
        <PressCard title="Media kit" info="Open our Brand guidlines" onClick={() => {}} buttonTitle={"Download"} />
        <PressCard title="Executive Team Images" info="Open our Brand guidlines" onClick={() => {}} buttonTitle={"See all photos"} />
        <PressCard title="Frequently Asked Questions" info="Open our Brand guidlines" onClick={() => {}} buttonTitle={"Connect"} />
      </Box>
    </div>
  );
};
