import { Stack } from "@mui/material";
import { FormInput, FormButton, useUtilityContent } from "suregifts-component-lib";

export const VoucherSummaryView = () => {
  const {
    submitForm,
    sending,
    form: {
      register,
      watch,
      formState: { errors, isValid },
    },
  } = useUtilityContent();
  return (
    <Stack spacing={3} sx={{ marginTop: "30px" }}>
      <FormInput isNumber required value={watch("voucherCode")} label={"Voucher Code:"} {...register("voucherCode")} helperText={errors.voucherCode?.message} />
      <FormInput type="password" isNumber charLength={6} value={watch("pin")} required label={"Pin:"} {...register("pin")} helperText={errors.pin?.message} />
      <FormButton disabled={!isValid} label="Pay with Voucher" type="submit" fullWidth onClick={submitForm} loading={sending} />
    </Stack>
  );
};
