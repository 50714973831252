import React from "react";
import { StoreLocatorData } from "../../services/merchant";
import { Box, Dialog, DialogTitle, IconButton, Typography, useTheme } from "@mui/material";
import { StoreLocationCard } from "./StoreLocationCard";
import { title } from "process";
import CloseIcon from "@mui/icons-material/Close";

type LocationDialogProps = {
  merchant: StoreLocatorData;
  handleClose: () => void;
};

function LocationDialog({ merchant: data, handleClose }: LocationDialogProps) {
  const theme = useTheme();
  return (
    <Dialog
      open={true}
      // maxWidth="md"
      onClose={handleClose}
      PaperProps={
        {
          // sx: { backgroundColor: "" },
        }
      }
    >
      <DialogTitle
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
          flexDirection: "column",
        }}
        component={"div"}
      >
        <Typography sx={{ fontSize: "10px", fontWeight: 500, color: "text.secondary" }}>ALL LOCATIONS</Typography>
        <div style={{ fontSize: "30px", fontWeight: 700, textAlign: "center" }}>{data.name}</div>
        <IconButton onClick={handleClose} sx={{ position: "absolute", right: "0", top: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          display: "grid",
          marginTop: "1.875rem",
          rowGap: "50px",
          columnGap: "30px",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2,1fr)",
          },
        }}
      >
        {data.stores?.map((item) => (
          <StoreLocationCard key={item.id} data={item} />
        ))}
      </Box>
    </Dialog>
  );
}

export default LocationDialog;
