import { Typography, Box, useTheme } from "@mui/material";
import { FormButton, GiftCardView } from "suregifts-component-lib";
import { useGetFeatureGiftCards } from "../../services/gift";
import { useNavigate } from "react-router-dom";

export const FeaturedVocuhersSection = () => {
  const { data } = useGetFeatureGiftCards();
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <div style={{ textAlign: "center", marginTop: "8.875rem", display: "grid", gap: "40px", justifyContent: "center" }}>
      <Typography sx={{ fontSize: "2.8125rem", fontWeight: 700, lineHeight: "110%" }}>Top Gift Vouchers of the Day</Typography>

      <Box
        sx={{
          display: "grid",
          justifyContent: "center",
          gap: "1.25rem",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2,1fr)",
          },
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4,1fr)",
          },
        }}
      >
        {data?.map((item) => (
          <GiftCardView
            key={item.giftId}
            gift={item}
            onClick={() => {
              navigate(`/gift-cards/${item.giftId}`);
            }}
          />
        ))}
      </Box>
      <FormButton label="See all Vouchers" sx={{ justifySelf: "center" }} onClick={() => navigate("/gift-cards")} />
    </div>
  );
};
