import { Typography } from "@mui/material";
import { formatMoney } from "suregifts-component-lib";
import { AddItemModel } from "../../../services/cart";
import { useGetGiftCardById } from "../../../services/gift";

type CartSmallGiftViewProps = {
  model: AddItemModel;
};
export const CartSmallGiftView = ({ model }: CartSmallGiftViewProps) => {
  const { data } = useGetGiftCardById(model?.giftId);

  return (
    <div style={{ display: "flex", gap: "20px", maxWidth: "270px", whiteSpace: "nowrap", overflow: "hidden" }}>
      <img src={data?.imageUrl} style={{ width: "83px", height: "50px", borderRadius: "10px", objectFit: "cover" }}></img>
      <div>
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>{data?.name}fads asf asdf asfd</Typography>
        <Typography sx={{ fontSize: "20px", fontWeight: 700, lineHeight: "24px", marginTop: "5px" }}>{formatMoney(model?.amount, data?.currency)}</Typography>
      </div>
    </div>
  );
};
