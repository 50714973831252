import { Box, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { ProfileTopButton } from "./ProfileTopButton";
import { useNavigate } from "react-router-dom";
import { useGetRecentOrders } from "../../../services/order";

function OrderHistorySection() {
  const { data: orders } = useGetRecentOrders();
  const navigate = useNavigate();
  return (
    <Box>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>Order History</Typography>
        <ProfileTopButton
          label={"View All"}
          onClick={() => {
            navigate("/orders");
          }}
        />
      </div>
      <div style={{ display: "grid", gap: "30px", marginTop: "45px" }}>
        {orders?.map((item) => (
          <div key={item.id} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <Typography sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "22px", color: "text.secondary" }}>{moment(item.dateCreated).fromNow()}</Typography>
              <Typography sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "24px", marginTop: "7px" }}>ID #{item.orderNumber}</Typography>
            </div>
            <Typography sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "24px" }}> {item.amountDue}</Typography>
          </div>
        ))}
      </div>
    </Box>
  );
}

export default OrderHistorySection;
