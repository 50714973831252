import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Stack } from "@mui/material";
import { register } from "numeral";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormInput, FormSelect, YupShape, formatMoney, useAuthContext } from "suregifts-component-lib";
import { AddItemModel, useGetDeliveryStates } from "../../../../services/cart";
import { useGetGiftCardById } from "../../../../services/gift";
import { useCartDeliveryContext } from "../useCartDeliveryContext";
import * as yup from "yup";
import Typography from "@mui/material/Typography/Typography";
import { InfoView } from "./PreviewSection";

const schema = yup
  .object<YupShape<AddItemModel>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phoneNumber: yup.string().required(),
    stateId: yup.string().required(),
    city: yup.string().required(),
  })
  .required();
function PersonalInfomationSection() {
  const { model, updateModel } = useCartDeliveryContext();

  const { user } = useAuthContext();
  const {
    register,
    watch,
    formState: { errors, isValid },
    control,
  } = useForm<AddItemModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: model.firstName,
      lastName: model.lastName,
      phoneNumber: model.phoneNumber,
      address: model.address,
      stateId: model.stateId || "",
      city: model.city,
    },
  });

  const { data: states } = useGetDeliveryStates();

  const stateNames = useMemo(() => {
    if (!states) return [];
    return states.map((x) => ({ id: x.id, name: `${x.state} (${formatMoney(x.amount, user?.currency)})` }));
  }, [states]);

  useEffect(() => {
    updateModel({ ...watch(), isValid: isValid });
  }, [watch("address"), watch("firstName"), watch("lastName"), watch("phoneNumber"), watch("stateId"), watch("city"), isValid]);

  const deliveryPrice = useMemo(() => {
    const stateId: any = watch("stateId");
    if (!stateId || !states) return undefined;

    const state = states!.filter((x) => x.id == stateId)[0];
    if (!state.amount) return undefined;

    return `${formatMoney(state.amount, user?.currency)}`;
  }, [watch("stateId")]);

  return (
    <Stack sx={{ marginTop: "30px" }} spacing="30px">
      <Stack spacing={2} direction="row">
        <FormInput required label="First name" {...register("firstName")} helperText={errors.firstName?.message} />
        <FormInput label="Last name" {...register("lastName")} helperText={errors.lastName?.message} />
      </Stack>
      <FormInput required label="Phone Number" {...register("phoneNumber")} helperText={errors.phoneNumber?.message} />
      <FormInput required label="Address" {...register("address")} helperText={errors.address?.message} />
      <Stack spacing={2} direction="row">
        <div style={{ width: "100%" }}>
          <Controller control={control} name="stateId" render={({ field }) => <FormSelect required fullWidth {...field} options={stateNames} label="State" helperText={errors.stateId?.message} />} />
          {deliveryPrice && (
            <Typography sx={{ fontSize: "12px", fontWeight: 500, lineHeight: "15px" }}>
              Delivery Fee:{" "}
              <Box component={"span"} sx={{ color: "success.main" }}>
                {deliveryPrice}
              </Box>{" "}
              will be added to your order
            </Typography>
          )}
        </div>
        <FormInput required label="City" {...register("city")} helperText={errors.city?.message} />
      </Stack>
      <Divider sx={{ gridColumn: "span 2", marginTop: "24px" }} />
      <div style={{ gridColumn: "span 2", display: "flex", flexDirection: "column", gap: "10px" }}>
        <InfoView text={"Timeline for physical cards delivery is 3-5 working days."} />
      </div>
    </Stack>
  );
}

export default PersonalInfomationSection;
