import { Box, Button, Stack } from "@mui/material";
import { TitleDetailView, OrderItemStatusLabel, OrderStatusType, WalletType, OrderStatusLabel } from "suregifts-component-lib";
import { useMemo, useState } from "react";
import { OrderInfoDto } from "../../../services/order";
import { useReaddOrder } from "../../../services/cart";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { ConfirmBankTransferView } from "../../cart/completed/resultViews/ConfirmBankTransferModal";
type GiftCartSectionProps = {
  data?: OrderInfoDto;
  selectedIndex?: number;
};
const buttonStyles = { padding: "9px 1rem", borderRadius: "10px", fontSize: "12px", lineHeight: "15px" };
export const GiftCartSection = ({ data: order, selectedIndex }: GiftCartSectionProps) => {
  const { mutate: readd, isLoading } = useReaddOrder();
  const { refetchCart } = useGlobalContext();
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const closeModal = () => setConfirm(false);
  const recipient = useMemo(() => {
    if (!order) return undefined;
    return order!.recipients[selectedIndex!];
  }, [selectedIndex]);

  if (!recipient || !order) {
    return <div></div>;
  }
  return (
    <>
      <Box>
        <div
          style={{
            textAlign: "right",
            display: "flex",
            gap: "10px",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            color="secondary"
            sx={buttonStyles}
            onClick={() => {
              readd(order?.id, {
                onSuccess: () => {
                  toast.success("Recipients added to cart successfully.");
                  refetchCart();
                  navigate("/cart");
                },
              });
            }}
          >
            Order Again
          </Button>
          {order?.statusId === OrderStatusType.Checkedout && order.paymentMethodId === WalletType.BankTransfer && (
            <Button color="success" sx={buttonStyles} onClick={() => setConfirm(true)}>
              Confirm Payment
            </Button>
          )}
        </div>
        <Stack spacing={"30px"} sx={{ marginTop: "30px" }}>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Recipient Name" detail={`${recipient!.firstName} ${recipient!.lastName}`} />
            <TitleDetailView align="right" title="Order Status" detail={<OrderStatusLabel label={order?.status} status={order.statusId} />} />
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Recipinet Email" detail={recipient!.email} />
            <TitleDetailView align="right" title="Payment Method" detail={order!.paymentMethod} />
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Price" detail={recipient!.value} />
            <TitleDetailView align="right" title="Delivery Method" detail={recipient!.deliveryMedium} />
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Gift Card" detail={recipient!.title} />
            <TitleDetailView align="right" title="Delivery Status" detail={<OrderItemStatusLabel label={recipient!.status} status={recipient!.statusId} />} />
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Personal message" detail={recipient!.message} />
          </Stack>
        </Stack>
      </Box>
      {confirm && (
        <ConfirmBankTransferView
          onSubmit={() => {
            closeModal();
            navigate(`/orders/${order.id}`, { replace: true });
          }}
          invoiceNumber={order.invoiceNumber}
          handleClose={closeModal}
        />
      )}
    </>
  );
};
