import { Stack, Box, Typography, SxProps } from "@mui/material";
import React, { useMemo } from "react";
import { VoucherData } from "../../../services/voucher";
import { VoucherStatusLabel } from "../../../components/atoms/VoucherStatusLabel";
import moment from "moment";
type VoucherCardProps = {
  data: VoucherData;
  onClick?: () => void;
};
export const defaultStoreImage = "https://storagesuregiftsdev.blob.core.windows.net/store-images/Default_Gift_Image.jpg";

const VoucherCard = ({ data: item, onClick = () => {} }: VoucherCardProps) => {
  const hasExpired = useMemo(() => moment() > moment(item.expiryDate), [item.expiryDate]);
  return (
    <Stack spacing={"10px"} sx={{ cursor: "pointer" }} onClick={onClick}>
      <Box sx={{ height: "176px", borderRadius: "18px", objectFit: "cover" }} component="img" src={item.imageUrl ?? defaultStoreImage} />
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
          "& .MuiTypography-root": {
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "17px",
          },
        }}
      >
        <Typography sx={{ color: item.isExpired ? "error.main" : "text.secondary" }}>
          {hasExpired ? "Expired" : "Expiring"} {moment(item.expiryDate).format("DD.MM.YYYY")}
        </Typography>
        <VoucherStatusLabel text={item.status} status={item.statusId} />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
          "& .MuiTypography-root": {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "22px",
          },
        }}
      >
        <Typography>{item.merchantName}</Typography>
        <Typography>{item.balance}</Typography>
      </Box>
    </Stack>
  );
};

export default VoucherCard;
