import { useQuery } from "react-query";
import { PageFilter, useAxios, Count, ServerError } from "suregifts-component-lib";

export const useGetOrders = (params: PageFilter) => {
  const axios = useAxios();
  return useQuery<Count<OrderData>, ServerError>({
    queryKey: ["orders", params],
    keepPreviousData: true,
    initialData: { total: 0, items: [] },
    queryFn: () =>
      axios.get("/orders/api", {
        params,
      }),
  });
};
export const useGetOrderById = (orderId, onSuccess?: () => void) => {
  const axios = useAxios();
  return useQuery<OrderInfoDto, ServerError>({
    queryKey: ["order-byId"],
    enabled: !!orderId,
    queryFn: () => axios.get(`/orders/api/${orderId}`, {}),
    onSuccess: onSuccess,
    refetchOnWindowFocus: false,
  });
};
export const useGetRecentOrders = () => {
  const axios = useAxios();
  return useQuery<Count<OrderData>, ServerError, OrderData[]>({
    queryKey: ["recent-orders"],
    keepPreviousData: true,

    queryFn: () =>
      axios.get("/orders/api", {
        params: { page: 1, size: 5 },
      }),
    select: (data) => data.items,
  });
};

export interface OrderData {
  id: number;
  dateCreated: string;
  statusId: number;
  status: string;
  orderNumber: string;
  invoiceNumber: string;
  quantity: number;
  paymentMethod: string;
  fee: string;
  hasCoupon: boolean;
  subTotal: string;
  shipping: string;
  discount: string;
  amountDue: string;
  coupon: string;
  printingFee: string;
}
export interface OrderInfoDto extends OrderData {
  recipients: RecipientData[];
  paymentMethodId: number;
  paymentMethod: string;
}

export interface RecipientData {
  deliveryStatus: string;
  deliveryStatusId: number;
  timestamp: string;
  deliveryMediumId: number;
  deliveryMedium: string;
  address: string;
  city: string;
  stateId: number;
  state: string;
  dateCreated: string;
  id: number;
  giftId: string;
  bonus: string;
  value: string;
  imageUrl: string;
  statusId: number;
  status: string;
  title: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  message: string;
}
