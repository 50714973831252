import { Stack, Typography, Box } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { SuccessTickIcon, FormButton, ErrorTickIcon } from "suregifts-component-lib";
import { CheckoutResult, useReaddOrder } from "../../../../services/cart";
import { useGlobalContext } from "../../../../contexts/GlobalContext";

type FailedCheckoutViewProps = {
  orderId?: number;
};
export const FailedCheckoutView = ({ orderId }: FailedCheckoutViewProps) => {
  const navigate = useNavigate();
  const { cart, refetchCart } = useGlobalContext();
  const { mutate: readd, isLoading } = useReaddOrder();
  return (
    <Stack sx={{ maxWidth: "430px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} spacing={3}>
      <ErrorTickIcon sx={{ width: 80, height: 80, color: "error.main" }} />
      <Typography sx={{ fontSize: "30px", fontWeight: 700, lineHeight: "40px" }}>Payment Failed</Typography>
      <Typography sx={{ fontSize: "18px" }}>There was an error precessing your transaction, please contact your bank or try again</Typography>
      <FormButton
        label="Try Again"
        sx={{ width: "300px" }}
        onClick={() => {
          readd(orderId, {
            onSuccess: () => {
              refetchCart();
              navigate("/cart");
            },
          });
        }}
      />
      <Box component={Link} to="/account" sx={{ color: "inherit", textDecoration: "none" }}>
        No Thanks
      </Box>
    </Stack>
  );
};
