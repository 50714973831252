import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useContactContext } from "../ContactContext";
import { EventListItem } from "../add-edit/EventListItem";
import CloseIcon from "@mui/icons-material/Close";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import { toast } from "react-toastify";
import { LoadingBox } from "suregifts-component-lib";
import { useDeleteEvent } from "../../../../services/contact";

function EventsModal({}) {
  const { state, dispatch } = useContactContext();
  const handleClose = () => {
    dispatch({ type: "Main" });
  };
  const { mutate: deleteEvent, isLoading: isDeleting } = useDeleteEvent(state.contact?.id);
  return (
    <>
      <Dialog open={state.pageType === "Events"} onClose={handleClose} scroll="body">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", gap: "30px", alignItems: "center" }}>
          Events
          <div>
            <Button color="success" size="small" onClick={() => dispatch({ type: "AddEvent" })} sx={{ paddingY: "1px", borderRadius: "50vh" }} startIcon={<NotificationAddIcon />}>
              Add Event
            </Button>
            <IconButton onClick={handleClose} sx={{}}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <LoadingBox loading={isDeleting}>
            {state.contact?.events.map((item, i) => (
              <EventListItem
                key={item.id}
                data={item}
                onDeleteClick={() => {
                  deleteEvent(item.id, {
                    onSuccess: (data) => {
                      toast.success("Event Deleted successfully.");
                      dispatch({ type: "Events", param: data });
                    },
                  });
                }}
                onEditClick={() => {
                  dispatch({ type: "EditEvent", param: { ...item, reminderType: item.reminderTypeId, repeatType: item.reminderTypeId } });
                }}
              />
            ))}
          </LoadingBox>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EventsModal;
