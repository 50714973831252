import { Typography } from "@mui/material";
import { VoucherStatusType } from "suregifts-component-lib";
export type VoucherStatusLabelProps = {
  text: string;
  status: VoucherStatusType;
};
export const VoucherStatusLabel = ({ text, status }: VoucherStatusLabelProps) => {
  const getColor = () => {
    switch (status) {
      case VoucherStatusType.Active:
        return "success.main";
      case VoucherStatusType.Pending:
      case VoucherStatusType.Generated:
        return "warning.main";
      default:
        return "error.main";
    }
  };
  return (
    <Typography component={"span"} color={getColor()}>
      {text}
    </Typography>
  );
};
