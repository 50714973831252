import { Typography } from "@mui/material";

export const FaceLabel = ({ text }) => {
  return (
    <Typography
      sx={{
        textTransform: "uppercase",
        color: "text.disabled",
        textAlign: "center",
        fontSize: "12px",
        fontWeight: 500,
        marginTop: "8px",
      }}
    >
      {text}
    </Typography>
  );
};
