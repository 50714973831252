import { Typography } from "@mui/material";
import { useCartDeliveryContext } from "../useCartDeliveryContext";
import { CSSProperties } from "react";
const style: CSSProperties = {
  background: "linear-gradient(180deg, #c8c8c9 -80%, #ffffff 52.8%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingInline: "40px",
  textAlign: "center",
};

export const LeftBottom = () => {
  const {
    model: { message },
  } = useCartDeliveryContext();
  return (
    <div style={style}>
      <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>Special Message</Typography>
      <Typography sx={{ fontSize: "14px", fontWeight: 300 }} component="div" dangerouslySetInnerHTML={{ __html: message }}></Typography>
    </div>
  );
};
