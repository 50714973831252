import { Box, Stack } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DeleteIcon, FormButton, FormInput, PageHeaderTitle, useDocumentTitle, useNotificationContext } from "suregifts-component-lib";
import { VoucherMergeModel, VoucherSplitModel, useMergeVoucher, useSplitVoucher } from "../../../../services/voucher";
import { IconButton } from "@mui/material";
import Card from "@mui/material/Card";

function VoucherMergeScreen() {
  const [searchParms] = useSearchParams();
  const { message } = useNotificationContext();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    formState: { errors, isValid },
  } = useForm<VoucherMergeModel>({
    defaultValues: {
      codes: [searchParms.get("voucherCode") || "", ""],
    },
  });
  useDocumentTitle("Merge Vouchers");
  const { isLoading, mutate: mergeVoucher, data } = useMergeVoucher();
  const submitForm = (model: VoucherMergeModel) => {
    if (model.codes.filter((x) => !x || x.length === 0).length > 0) {
      toast.info("All fields are required.");
      return;
    }
    mergeVoucher(model, {
      onSuccess: (data) => {
        message({
          buttonText: "Close",
          title: "Merge Successful",
          desc: "You have successfully merged vouchers",
          onClose: () => {
            navigate(`/vouchers/${data.voucherCode}`);
          },
        });
      },
    });
  };
  const handleValueChanged = (index, value) => {
    const codes = watch("codes");
    codes[index] = value;
    setValue("codes", codes);
  };
  return (
    <Card
      sx={{
        borderRadius: "30px",
        backgroundColor: "primary.main",
        padding: "40px 50px",
        gap: "20px",
        display: "grid",
      }}
    >
      <PageHeaderTitle title="Merge Voucher" sx={{ marginBottom: "30px" }} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "30px",
        }}
      >
        {watch("codes").map((item, i) => (
          <FormInput
            key={`vouchers-${i}`}
            label={`Voucher Code ${i + 1}`}
            endAdornment={
              i > 1 ? (
                <IconButton
                  sx={{ padding: 0 }}
                  color="error"
                  onClick={() => {
                    const vouchers = [...watch("codes")];
                    vouchers.splice(i, 1);
                    setValue("codes", vouchers);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : undefined
            }
            value={item}
            onChange={(e) => handleValueChanged(i, e.target.value)}
          />
        ))}
      </Box>
      <Box sx={{ marginTop: "30px", display: "flex", gap: "20px" }}>
        <FormButton label="Merge" loading={isLoading} disabled={!isValid} onClick={handleSubmit(submitForm)} sx={{}} />
        <FormButton
          label="Add Voucher"
          color="primary"
          sx={{
            backgroundColor: "primary.dark",
          }}
          onClick={() => {
            const codes = watch("codes");
            codes.push("");
            setValue("codes", codes);
          }}
          disabled={watch("codes").length >= 4}
        />
      </Box>
    </Card>
  );
}

export default VoucherMergeScreen;
