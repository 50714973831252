import { Box, Container, Stack, useTheme } from "@mui/material";

import { OrderAmountSection } from "./OrderAmountSection";
import { PaymentMethodSection } from "./PaymentMethodSection";
import { CartItemsListSection } from "./CartItemsListSection";
import { CouponSection } from "./CouponSection";
import { CartJourneySection } from "../CartJourneySection/CartJourneySection";

function CartScreen() {
  const theme = useTheme();

  return (
    <Container sx={{ marginTop: "40px" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: " 1fr",
          gap: "30px",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "1fr 330px ",
          },
        }}
      >
        <div>
          <CartItemsListSection />
          <CouponSection />
        </div>
        <Stack spacing={3}>
          <PaymentMethodSection />
          <OrderAmountSection />
        </Stack>
      </Box>
      <CartJourneySection activeStep={2} />
    </Container>
  );
}

export default CartScreen;
