import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";
import { useCartPersonalizeContext } from "../personalize/CartPersonalizeContext";

export const PersonalizeButtons = () => {
  const navigate = useNavigate();
  const { model } = useCartPersonalizeContext();
  const [searchParams] = useSearchParams();
  return (
    <>
      <FormButton label="Change Gift" color="primary" onClick={() => navigate("/gift-cards")} />
      <FormButton disabled={!model.message || !model.bgPicture} label="Choose Delivery" onClick={() => navigate(`/cart/delivery-method?${createSearchParams(searchParams)}`)} />
    </>
  );
};
