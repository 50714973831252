import React, { useState } from "react";
import "./main.scss";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Socials } from "./Socials";
import FormView from "./FormView";
import FormSuccessView from "./FormSuccessView";
import { useDocumentTitle } from "suregifts-component-lib";

function DominoPromoScreen() {
  const [submitted, setSubmitted] = useState(false);
  useDocumentTitle("Dominos Promo");
  return (
    <div className="container">
      <header>
        <div className="logo">
          <Link to="/">
            <img src="/assets/images/promo/suregifts-white.svg" alt="Suregifts logo" />
          </Link>
          <img src="/assets/images/promo/dominos-logo.png" alt="Dominos Logo" />
        </div>
        <Link className="home-a" to="/">
          Visit SureGifts
        </Link>
      </header>
      <main style={{ backgroundImage: "url(/assets/images/promo/bg.png)" }}>
        <section className="left">
          <img alt="Eat and Go image" src="/assets/images/promo/promo-image.png" />
          <h1>A Special Treat for You</h1>
          <p>As the year gradually ends, we want to specially appreciate you & your team for your patronage with a large box of Pizza & cups of Ice-cream, courtesy of our partners, Eat'n'Go.</p>
        </section>
        <section className="right">
          {submitted ? (
            <FormSuccessView />
          ) : (
            <FormView
              onSuccess={() => {
                setSubmitted(true);
              }}
            />
          )}

          <Socials />
        </section>
      </main>
    </div>
  );
}

export default DominoPromoScreen;
