import { Box } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";

function FormSuccessView() {
  const [submitted, setSubmitted] = useState(true);
  const navigate = useNavigate();
  return (
    <Box
      className="success-view form-border"
      sx={{
        alignSelf: "self-start",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          padding: "74px 0",
          ".thank-you": {
            fontSize: "24px",
            fontWeight: 700,
          },
          ".thank-you-desc": {
            fontSize: "14px",
          },
          ".thank-you-appreciate": {
            fontSize: "14px",
            fontWeight: 700,
          },
        }}
      >
        <img src="/assets/images/promo/check-circle-broken.svg" alt="" />
        <p className="thank-you">Thank You!</p>
        <p className="thank-you-desc">Thanks for your patronage over the years. </p>
        <p className="thank-you-appreciate">We appreciate you!</p>
      </Box>
      <Box
        className=""
        sx={{
          textAlign: "center",
          padding: "30px 25px",
          borderTop: "2px solid #717273",

          ".title": {
            fontSize: "24px",
            fontWeight: 700,
          },
          ".link": {
            fontSize: "14px",
            color: "inherit",
          },
          ".okay-button": {
            marginTop: "20px",
          },
        }}
      >
        <p className="title">Check your inbox for your free pizza voucher</p>
        <Link className="link" to="/how-to-use">
          Click here to see where you can redeem your voucher
        </Link>
        <FormButton color="error" fullWidth label="Okay" className="okay-button" onClick={() => navigate("/")}></FormButton>
      </Box>
    </Box>
  );
}

export default FormSuccessView;
