import { Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useMemo, useState } from "react";
import { NavItemProps } from "./links";
import { useIsLinkActive } from "../../hooks/useIsLinkActive";

export const NavItem = ({ label, to, children, onClick = () => {} }: NavItemProps) => {
  const isActive = useIsLinkActive(to);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  return (
    <li>
      <Box
        component={Link}
        sx={{
          padding: "1rem",
          textDecoration: "none",
          color: "inherit",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "14px",
          lineHeight: "17px",
          fontWeight: 500,
          borderRadius: "18px",
          ...(isActive && {
            backgroundColor: "primary.light",
            boxShadow: "inset 4px 0px 0px #92FBB3",
            backdropFilter: "blur(27px)",
          }),
        }}
        onClick={(e) => {
          if (children) {
            e.preventDefault();
            onClick(e);
            setOpen((prev) => !prev);
          }
        }}
        to={to}
      >
        {label}
        {children && open && <ExpandLessIcon />}
        {children && !open && <ExpandMoreIcon />}
      </Box>
      {open && children && (
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
        >
          {children.map((item, i) => (
            <NavItemInner key={`${item.to}-${i}`} to={item.to} label={item.label} />
          ))}
        </ul>
      )}
    </li>
  );
};

const NavItemInner = ({ to, label }: NavItemProps) => {
  const isActive = useIsLinkActive(to);
  return (
    <li>
      <Box
        component={Link}
        sx={{
          padding: "1rem",
          textDecoration: "none",
          color: "text.disabled",
          display: "block",
          fontSize: "14px",
          lineHeight: "17px",
          fontWeight: 500,
          borderRadius: "18px",
          ...(isActive && {
            color: "inherit",
          }),
        }}
        to={to}
      >
        {label}
      </Box>
    </li>
  );
};
