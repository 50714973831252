import { Button } from "@mui/material";

export const PaymentButton = ({ icon, title, active, onClick }) => {
  return (
    <Button
      onClick={onClick}
      fullWidth
      startIcon={icon}
      color={active ? "success" : "primary"}
      sx={{
        fontSize: "18px",
        borderRadius: "20px",
        padding: "20px",
        "&.MuiButton-containedPrimary": {
          backgroundColor: "primary.main",
        },
      }}
    >
      {title}
    </Button>
  );
};
