import { Box, Button } from "@mui/material";
import { NavItem } from "./NavItem";
import { UserStatusView } from "../../components/organisms/UserStatusView";
import LogoutIcon from "@mui/icons-material/Logout";
import { links } from "./links";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "suregifts-component-lib";
import { useLogout } from "../../services/auth";

export const Nav = () => {
  const { pathname } = useLocation();
  const { signOut } = useAuthContext();
  const { mutate: logout } = useLogout();
  const navigation = useNavigate();

  return (
    <>
      {pathname !== "/account-settings" && (
        <UserStatusView
          size="medium"
          actionComponent={
            <Button
              onClick={() => {
                logout(undefined, {
                  onSuccess: () => {
                    signOut();
                  },
                });
                navigation("/");
              }}
              variant="text"
              startIcon={<LogoutIcon />}
              sx={{
                color: "text.secondary",
                padding: "0",
                fontSize: "14px",
                alignItems: "center",
                "& .MuiButton-startIcon": {
                  marginLeft: 0,
                },
              }}
            >
              Logout
            </Button>
          }
        />
      )}
      <Box
        component={"ul"}
        sx={{
          listStyle: "none",
          padding: "0",
          marginLeft: "-1rem",
        }}
      >
        {links.map((item) => (
          <NavItem key={item.label} {...item} />
        ))}
      </Box>
    </>
  );
};
