import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { DialogTitle, FormButton, FormInput, useAuthContext, useNotificationContext } from "suregifts-component-lib";
import { useForm } from "react-hook-form";
import { ProfileInfo, useSendVerifyPhoneCode, useVerifyPhone, VerifyModel } from "../services/auth";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useEffect } from "react";

function PhoneVerificationModal() {
  const { message } = useNotificationContext();
  const navigate = useNavigate();
  const { saveToken, user } = useAuthContext();
  const localUser = user as ProfileInfo;
  const queryClient = useQueryClient();
  const { mutate: verifyPhone, isLoading: isVerifingPhone } = useVerifyPhone();
  const { mutate: sendPhoneCode } = useSendVerifyPhoneCode();

  useEffect(() => {
    if (!(localUser.phoneCountryCode && localUser.phoneNumber)) {
      navigate("/add-phone");
      return;
    }
    sendPhoneCode(undefined, {
      onSuccess: () => {},
      onError: (error) => {
        if (error.error === "ProfileUpdateRequired") {
          navigate("/add-phone");
        }
      },
    });
  }, []);

  const submitForm = (model) => {
    verifyPhone(model, {
      onSuccess: (data) => {
        message({ title: "Phone number verified successfully." });
        queryClient.invalidateQueries("profile");
        saveToken(data.token, data.refreshToken, data);
        handleClose();
      },
    });
  };

  const { register, handleSubmit } = useForm<VerifyModel>();
  const handleClose = () => {
    navigate("..");
  };
  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title={"Verify Phone Number"} handleClose={handleClose} />
      <DialogContent>
        <FormInput label="Enter Otp" {...register("otpCode")} />
      </DialogContent>
      <DialogActions>
        <FormButton label="Confirm" fullWidth onClick={handleSubmit(submitForm)} loading={isVerifingPhone} />
      </DialogActions>
    </Dialog>
  );
}

export default PhoneVerificationModal;
