import { Card } from "@mui/material";
import { title } from "process";
import React from "react";
import { PageHeaderTitle } from "suregifts-component-lib";

function VoucherServiceCard({ title, children }) {
  return (
    <Card
      sx={{
        borderRadius: "30px",
        backgroundColor: "primary.main",
        padding: "40px 50px",
        gap: "20px",
        display: "grid",
      }}
    >
      <PageHeaderTitle title={title} sx={{ marginBottom: "30px" }} />
      {children}
    </Card>
  );
}

export default VoucherServiceCard;
