import { Typography, Box, Container } from "@mui/material";

export const BlogDetailsSection = () => {
  return (
    <>
      <Container>
        <div style={{ marginTop: "5rem" }}>
          <Typography sx={{ lineHeight: "130%" }}>October 21st, 2021</Typography>
          <Typography sx={{ fontSize: "2.75rem", fontWeight: 700, lineHeight: "130%", marginTop: "0.5rem" }}>Title of the blog post/press release</Typography>
        </div>
      </Container>
      <Box component={"img"} src="/assets/images/dummy/blog-lg.png" sx={{ width: "100%", display: "block", maxHeight: "34.6875rem", marginTop: "2.5rem" }} />
      <Container>
        <Box sx={{ marginTop: "2.5rem", lineHeight: "2.125rem" }}>
          Aliquet neque tristique tempus molestie lectus at pellentesque enim justo. Urna quis vel libero at. Iaculis nisl fermentum arcu, ac in. In placerat ut vitae sed mauris sapien odio dictumst. Phasellus sagittis facilisis amet, aliquam
          placerat id aliquam enim. In in enim et urna semper. Mattis eu tortor fermentum, libero, enim, malesuada mauris non. Nulla risus nulla faucibus sed varius. Mollis bibendum vel sed libero. Lacus tristique vestibulum vivamus phasellus
          quisque. Id vitae ac nec duis. <br />
          <br />
          Condimentum varius velit quam eu. Pharetra, tempor nibh blandit augue sit. Laoreet eleifend lectus eu quis tellus etiam turpis lacus ultrices. Adipiscing at tortor nulla auctor. Amet convallis velit augue blandit. Sed vitae pulvinar ut
          viverra rhoncus massa et id. <br />
          <br />
          Nunc malesuada eget ultrices diam. Vitae ac viverra nibh congue sed. Tellus elementum dignissim adipiscing sem. Mi ac pellentesque proin eros praesent morbi amet dictum. Volutpat gravida porta donec scelerisque interdum amet ornare nibh
          elementum. Arcu accumsan sem ut egestas vulputate. Etiam eget scelerisque a adipiscing vel ac imperdiet feugiat. Tristique arcu sed integer urna, risus sit. Sed amet, sed eget tempor a lacus, lorem. Cum orci id scelerisque ullamcorper
          consectetur blandit enim, eu congue. Mus scelerisque odio elit hendrerit odio odio eget sit tempus. In diam leo viverra nisl lacus vitae. Urna etiam mauris id dapibus nunc. <br />
          <br />
          Maecenas etiam urna blandit justo, vulputate ultrices enim enim maecenas. Tempus feugiat senectus vestibulum, facilisis eu semper orci pulvinar lectus. Lacus mattis eget vitae purus tincidunt dictumst. Amet, egestas nunc ornare arcu in
          aliquam molestie. Ut lacus, bibendum erat velit. Gravida nunc eget sit vulputate aliquam posuere. Felis, sagittis vel felis pulvinar dignissim tincidunt sed ultrices. Est lacus, vel id nunc, egestas dui urna.
          <br />
          <br />
          Praesent donec faucibus donec eleifend eu vitae, est sit praesent. Tincidunt dictumst nibh ac pretium in malesuada. Facilisis facilisi tristique vulputate at. Tempor, tristique elementum cursus tincidunt lorem. Quis adipiscing egestas nulla
          sit egestas amet nisl turpis varius. Ac egestas justo integer egestas feugiat. Ut diam a posuere tincidunt. Sed ac id pharetra, nec. Turpis praesent felis venenatis risus euismod potenti nisi purus. At sed lorem placerat amet arcu. Sed sed
          rhoncus nisl est nunc. Nam potenti viverra scelerisque est eu, amet mauris. Gravida amet amet pellentesque facilisis faucibus dictum nulla. Eu, viverra sed vulputate sit. Id diam volutpat, pharetra enim, purus posuere eget felis. Sit
          tortor, gravida arcu lorem. Vitae faucibus egestas elit eget luctus commodo mattis est. Metus viverra duis velit eu, accumsan enim imperdiet sodales rhoncus. Mi magna etiam nulla volutpat. Commodo nulla pretium tincidunt id urna amet ipsum
          massa. <br />
          <br />
          Sed molestie nulla nunc eget pretium ultricies massa. Sem id mauris quis habitant bibendum cras euismod adipiscing bibendum. Quisque quam interdum consequat consequat et viverra molestie imperdiet. Hendrerit sit nunc non auctor. Donec eget
          bibendum diam, in elit quis aliquam dui. Aliquam viverra ante auctor scelerisque faucibus. Non ornare maecenas hendrerit integer risus. Faucibus nullam id lorem aenean. Hendrerit at ridiculus sed tortor a ornare tortor ut sem. Donec aliquam
          lectus nec ut feugiat aliquam arcu venenatis, ipsum. Dolor neque, in consequat dictum donec tortor lacus. Adipiscing volutpat in eu volutpat quam aliquam ut. Egestas ac scelerisque arcu hendrerit quis ridiculus cras condimentum. Sed
          bibendum arcu nunc ut dolor. Etiam pellentesque non mauris duis feugiat eget scelerisque. At dapibus interdum nisl ut euismod sed non varius. Et elementum, accumsan in porttitor lectus ac sed in. Iaculis dignissim nec turpis duis interdum
          id morbi ut ipsum. Feugiat id egestas vitae, faucibus etiam feugiat vestibulum nisl adipiscing. Amet urna, sit risus eu dis cras ac. Est eu in commodo, massa porttitor amet placerat. Sit amet ac sed sit ut.
        </Box>
      </Container>
    </>
  );
};
