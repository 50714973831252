import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { VoucherInfoDto, useGetVoucherById } from "../../../services/voucher";
import { useSearchParams } from "react-router-dom";
type VoucherServiceContextProps = {
  voucher?: VoucherInfoDto;
  isValidCode: boolean;
};
const VoucherServiceContext = createContext({} as VoucherServiceContextProps);

export const useVoucherServiceContext = () => useContext(VoucherServiceContext);

export const VoucherServiceContextProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const voucherCode = useMemo(() => searchParams.get("voucherCode") || "", [searchParams]);

  const isValidCode = useMemo(() => {
    return Boolean(voucherCode) && (voucherCode.length == 8 || voucherCode.length == 9 || voucherCode.length == 12);
  }, [voucherCode]);

  useEffect(() => {
    if (isValidCode) {
      getVoucherService(voucherCode);
    }
  }, [searchParams]);

  const { data: voucher, mutate: getVoucherService } = useGetVoucherById(false);

  return (
    <VoucherServiceContext.Provider
      value={{
        voucher,
        isValidCode,
      }}
    >
      {children}
    </VoucherServiceContext.Provider>
  );
};
