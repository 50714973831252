import { DialogProps, Dialog } from "@mui/material";

type AuthDialogProps = {} & DialogProps;
export const AuthDialog = ({ sx, ...others }: AuthDialogProps) => {
  return (
    <Dialog
      sx={[
        {
          "& .MuiDialog-paper": {
            padding: "0  !important",
          },
          "& div.MuiDialogActions-root": {
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0 !important",
            paddingY: "28px !important",
            borderTop: "1px solid rgb(245,245,245,0.2)",
            "& .MuiTypography-body1,& button": {
              fontSize: "14px !important",
              fontWeight: 500,
            },
          },
          "& .MuiDialogTitle-root": {
            background: "linear-gradient(90deg, rgba(255, 255, 255, 0.26) -7%, rgba(255, 255, 255, 0) 96.73%)",
            padding: "28px 38px 18px  !important",
            fontSize: "1.25rem",
            marginBottom: "0  !important",
            "& .MuiButtonBase-root": {
              right: "28px",
              top: "26px",
            },
          },
          "& .MuiDialogContent-root": {
            padding: "30px 40px  !important",
          },
          "& .MuiButton-root[type='submit']": {
            marginTop: "30px  !important",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...others}
    />
  );
};
