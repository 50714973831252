import { Box, Typography, SxProps, Card, Button, Tooltip } from "@mui/material";

import { StoreLocatorData } from "../../services/merchant";
import ContactTtitleDescView from "../profile/contact/list/ContactTtitleDescView";
import { useMemo } from "react";

type StoreLocationCardProps = {
  data: StoreLocatorData;
  onClick?: () => void;
};
const rootStyles: SxProps = {
  padding: "1.5625rem",
  borderRadius: "1.5625rem",
  position: "relative",
  borderWidth: 1,
  borderColor: "#353536",
  backdropFilter: "none",
  "& .option-view": {
    display: "none",
  },
  "&:hover .option-view": {
    display: "block !important",
  },
};
export const StoreLocationCard = ({ data, onClick }: StoreLocationCardProps) => {
  const item = useMemo(() => {
    let result = { ...data };
    if (data.stores && data.stores.length > 0) {
      const store = data.stores[0];
      result.address = result.address ?? store.address;
      result.email = result.email ?? store.email;
      result.phone = result.phone ?? store.phone;
    }
    return result;
  }, [data]);
  return (
    <Card sx={rootStyles}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <RecipientAvata data={item} />
          <div>
            <Tooltip title={item.name} placement="top">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "18px",
                  // whiteSpace: "nowrap",
                  // textOverflow: "ellipsis",
                }}
              >
                {item.name}
              </Typography>
            </Tooltip>
            {data.stores && (
              <Typography
                sx={{
                  color: "text.secondary",
                  fontSize: "14px",
                  lineHeight: "17px",
                }}
              >
                {item.storeCount} location
              </Typography>
            )}
          </div>
        </Box>
      </Box>
      <ContactTtitleDescView title={"Address"} multiline desc={item.address || "Nationwide"} />

      {typeof data.stores !== "undefined" && <ShowMoreButton data={data} onClick={onClick} hide={data.storeCount <= 1} />}
    </Card>
  );
};
const ShowMoreButton = ({ data, onClick, hide }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        "&.MuiButton-containedPrimary.MuiButton-root": {
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        },
        marginTop: "20px",
        padding: "15px",
        borderRadius: "16px",
        ...(hide && { visibility: "hidden" }),
      }}
      variant="contained"
      color="primary"
      fullWidth
      disabled={data.stores.length < 2}
    >
      More Locations
    </Button>
  );
};
const RecipientAvata = ({ data }: { data: StoreLocatorData }) => {
  const initials = useMemo(() => {
    const names = data.name
      .split(" ")
      .map((x) => x.trim())
      .filter((x) => x.length > 0);
    let split = names[0][0];
    if (names.length > 1) {
      split = split + names[1][0];
    }
    return split;
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: "white",
        color: "black",
        borderRadius: "16px",
        padding: "8px 10px",
        fontSize: "30px",
        fontWeight: 700,
      }}
    >
      {initials}
    </Box>
  );
};
