import { Box, Typography, useTheme } from "@mui/material";
import { DeliveryTruckIcon, IconView, SummaryBox, UserIcon } from "suregifts-component-lib";
import { useAccountContext } from "./AccountContext";
import { VoucherIcon } from "../../../icons/Icons";

export const SummarySection = () => {
  const theme = useTheme();
  const { profile } = useAccountContext();
  return (
    <Box
      sx={{
        display: "grid",
        gap: "20px",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "repeat(3,1fr)",
        },
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "repeat(3,1fr)",
        },
        "& .MuiTypography-body1:first-of-type": {
          fontSize: "24px",
          fontWeight: 500,
        },
        "& .MuiTypography-body1:nth-of-type(2)": {
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "17px",
          textTransform: "none",
        },
      }}
    >
      <SummaryCardWithIcon title={"Orders"} info={`${profile?.totalOrders} Total orders`} icon={DeliveryTruckIcon} />
      <SummaryCardWithIcon title={"Contact"} info={`${profile?.contacts} Contacts added`} icon={UserIcon} />
      <SummaryCardWithIcon title={"Vouchers"} info={`${profile?.vouchers}  Total Vouchers`} icon={VoucherIcon} />
    </Box>
  );
};
type SummaryCardWithIconProps = {
  title: string;
  info: string;
  icon?;
  color?;
  iconColor?;
};
export function SummaryCardWithIcon({ title = "", info = "Total orders", icon, color, iconColor }: SummaryCardWithIconProps) {
  return (
    <SummaryBox color={color}>
      <IconView icon={icon} iconColor={iconColor} />
      <div style={{}}>
        <Typography sx={{ fontSize: "20px", fontWeight: 700, lineHeight: "24px" }}>{title}</Typography>
        <Typography sx={{ fontSize: "10px", fontWeight: 500, color: "text.secondary", textTransform: "uppercase", marginTop: "7px", lineHeight: "17px" }}>{info}</Typography>
      </div>
    </SummaryBox>
  );
}
