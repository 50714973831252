import { Box } from "@mui/material";
import { FormButton } from "suregifts-component-lib";
import StaticHeader from "../../components/atoms/StaticHeader";
import StaticHeaderInfo from "../../components/atoms/StaticHeaderInfo";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";

export const SectionFive = () => {
  const { initialSetting } = useGlobalContext();
  const navigate = useNavigate();
  return (
    <Box sx={{ textAlign: "center !important", maxWidth: "md", margin: "12rem auto 10rem" }}>
      <StaticHeader>Reward your employees, consumers and fans with gift cards</StaticHeader>
      <StaticHeaderInfo>
        Easily reward, motivate, and show appreciation to your employees, customers, and business partners? Contact us today to discover how organisations are using our platform to retain top talent and foster loyal customer relationships.
      </StaticHeaderInfo>
      <FormButton
        sx={{ marginTop: "3rem" }}
        label="Visit Suregifts Business"
        onClick={() => {
          window.location.href = initialSetting?.businessUrl || "#";
        }}
      />
    </Box>
  );
};
