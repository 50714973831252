import React, { useEffect, useState } from "react";
import CheckoutResultModal from "./resultViews/CheckoutResultView";
import { Box, Container, IconButton } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { StorageKeys } from "../../../enums";
import { CheckoutResult } from "../../../services/cart";
import { BigCloseIcon } from "../../../icons/Icons";
import { useDocumentTitle } from "suregifts-component-lib";

function CartCompletedScreen() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [result] = useState<CheckoutResult | undefined>(() => {
    var data = sessionStorage.getItem(StorageKeys.CHECKOUT_RESULT);
    if (!data) {
      navigate("/");
      return;
    }
    return JSON.parse(data) as CheckoutResult;
  });
  useDocumentTitle("Cart");
  useEffect(() => {
    if (pathname !== "/cart/completed") {
      sessionStorage.removeItem(StorageKeys.CHECKOUT_RESULT);
    }
  }, [pathname]);
  if (!result) return <></>;
  return (
    <EmptyPageLayout>
      <div style={{ display: "flex", justifyContent: "center", top: "50%", left: "50%", transform: "translate(-50%, -50%)", alignItems: "center", position: "absolute" }}>
        <CheckoutResultModal data={result} />
      </div>
    </EmptyPageLayout>
  );
}
export const EmptyPageLayout = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.dark",
        width: "100%",
        height: "100vh",
        marginTop: "0",
        position: "relative",
        "&::before, &::after": {
          content: '""',
          display: "table",
        },
      }}
    >
      <Container sx={{ marginTop: "30px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Link to={"/"} replace>
          <img src="/assets/images/logo.svg" />
        </Link>
        <Link to="/" style={{ color: "inherit" }}>
          <BigCloseIcon />
        </Link>
      </Container>
      {children}
    </Box>
  );
};
export default CartCompletedScreen;
