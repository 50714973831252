import { createContext, useContext, useEffect, useReducer } from "react";
import AccountScreen from "./AccountScreen";
import { ProfileRoot, useGetProfile } from "../../../services/auth";
import { useDocumentTitle } from "suregifts-component-lib";
export type ScreenContextValueProps = {
  profile?: ProfileRoot;
  refetch: () => void;
};
const AccountContext = createContext({} as ScreenContextValueProps);

export const useAccountContext = () => {
  return useContext(AccountContext);
};

export const AccountContextProvider = () => {
  useDocumentTitle("Account Overview");
  const { data, isFetching, refetch } = useGetProfile();
  return (
    <AccountContext.Provider value={{ profile: data, refetch }}>
      <AccountScreen />
    </AccountContext.Provider>
  );
};
