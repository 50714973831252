import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { FormButton, PageHeaderTitle, FileUploader, useAuthContext } from "suregifts-component-lib";
import { ChangePinSection } from "./ChangePinSection";
import { CreatePinSection } from "./CreatePinSection";
import { ChangePasswordSection } from "./ChangePasswordSection";
import { ProfileInfoSection } from "./ProfileInfoSection";
import { useAccountSettingContext } from "./AccountSettingContext";
import { useRemovePicture, useSavePicture } from "../../../services/auth";
import { useQueryClient } from "react-query";
import { ProfilePictureView } from "./ProfilePictureView";

function AccountSettingScreen() {
  const { profile: user } = useAccountSettingContext();
  return (
    <div>
      <ProfilePictureView />
      <Card
        sx={{
          borderRadius: "30px",
          backgroundColor: "primary.main",
          padding: "40px 50px",
          display: "grid",
          gap: "80px",
          marginTop: "40px",
        }}
      >
        <ProfileInfoSection />
        <ChangePasswordSection />
        {user?.hasPin ? <ChangePinSection /> : <CreatePinSection />}
      </Card>
    </div>
  );
}

export default AccountSettingScreen;
