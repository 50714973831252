import { useMutation } from "react-query";
import { useAxios, ServerError } from "suregifts-component-lib";

export const useClaimPromo = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, ClaimPromoModel>((model) => {
    return axios.post(`/vouchers/api/claim-promo`, model);
  });
};
export interface ClaimPromoModel {
  fullName: string;
  companyName: string;
  email: string;
  phone: string;
}
