import { Box, Button, Typography } from "@mui/material";
import { FileUploader, FilterDropdown, FormLabel, OptionNaming } from "suregifts-component-lib";
import { useSaveBgImage, useDeleteBg, useGetBgImages } from "../../../services/gift";
import { GreetingCardImageView } from "./GreetingCardImageView";
import { useCartPersonalizeContext } from "./CartPersonalizeContext";
type SideBarSectionProps = {
  categories?: OptionNaming[];
};
export const SideBarSection = ({ categories }: SideBarSectionProps) => {
  const { mutate: saveBg } = useSaveBgImage();
  const { mutate: deleteBg } = useDeleteBg();
  const { data: bgImages, refetch: refetchBgs } = useGetBgImages();
  const { updateModel, model } = useCartPersonalizeContext();
  return (
    <div>
      <div>
        <Box
          sx={{
            display: "grid",
            gap: "16px",
            marginBottom: "12px",
            "& .MuiButton-root.MuiButton-textPrimary": {
              borderRadius: "1rem",
              color: "primary.contrastText",
              bgcolor: "primary.light",
              padding: "12px 25px",
              fontSize: "16px",
              fontWeight: 500,
              "& .MuiSvgIcon-root": {
                fontSize: "32px",
              },
            },
          }}
        >
          <FormLabel label="Choose Event Category" error={false} />
          <FilterDropdown onChange={(index, value) => updateModel({ greetingCardCategoryId: value })} value={model.greetingCardCategoryId} display={"Category"} options={categories} color="primary" fullWidth />
        </Box>
        <FileUploader
          onChange={(file) => {
            saveBg(file as string, {
              onSuccess: () => {
                refetchBgs();
              },
            });
          }}
          renderControl={(control) => (
            <Button color="success" onClick={control.onClick} variant="text" sx={{ padding: 0, fontSize: "14px" }}>
              Upload Custom Image
            </Button>
          )}
        />
        <Typography
          sx={{
            fontSize: "10px",
            color: "text.secondary",
          }}
        >
          Recommended size: 160px by 200px
        </Typography>
      </div>
      <div style={{ marginTop: "28px" }}>
        <Typography sx={{ fontSize: "14px" }}>My Images</Typography>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            marginTop: "20px",
          }}
        >
          {bgImages?.map((path, i) => (
            <GreetingCardImageView
              selected={path === model.bgPicture}
              onClick={() => {
                updateModel({ greetingCardId: undefined, bgPicture: path });
              }}
              onDeleteClick={() =>
                deleteBg(path, {
                  onSuccess: () => {
                    refetchBgs();
                  },
                })
              }
              url={path}
              key={`${path}-${i}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
