import { Dialog, DialogContent, Stack, RadioGroup, FormControlLabel, Radio, DialogActions } from "@mui/material";
import { DialogTitle, FormInput, DatePicker, FormSelect, FormLabel, FormButton, YupShape } from "suregifts-component-lib";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContactContext } from "../ContactContext";
import { EventModel, useGetReminderType, useGetRepeatTypes } from "../../../../services/contact";

type EventAddEditFormProps = {
  onSave: (model: EventModel) => void;
  handleClose: () => void;
};
const schema = yup
  .object<YupShape<EventModel>>({
    title: yup.string().required(),
    reminderType: yup.string().required(),
    repeatType: yup.string().required(),
    startDate: yup.string().required(),
  })
  .required();
function EventAddEditForm({ onSave = () => {}, handleClose }: EventAddEditFormProps) {
  const {
    state: { event, pageType },
  } = useContactContext();
  const { data: reminders } = useGetReminderType();
  const { data: repeats } = useGetRepeatTypes();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EventModel>({
    defaultValues: {
      reminderType: event?.reminderType || "0",
      repeatType: event?.repeatType || "0",
      startDate: event?.startDate || moment().toISOString(),
      description: event?.description,
      title: event?.title,
      id: event?.id,
    },
    resolver: yupResolver(schema),
  });

  return (
    <Dialog open={pageType === "AddEvent" || pageType === "EditEvent"}>
      <DialogTitle title="Add Event" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput label="Title" {...register("title")} />
          <Stack spacing={1} direction="row">
            <Controller control={control} name="startDate" render={({ field: { value, onChange } }) => <DatePicker label="Start" value={value} onChange={onChange} />} />
            <Controller control={control} name="repeatType" render={({ field: { value, onChange } }) => <FormSelect value={value} onChange={onChange} label="Repeat" options={repeats} />} />
          </Stack>
          <Controller
            control={control}
            name="reminderType"
            render={({ field: { value, onChange } }) => (
              <div>
                <FormLabel label={"Reminder"} error={!!errors.reminderType?.message} />
                <RadioGroup row value={value} onChange={(e) => onChange(e.target.value)}>
                  {reminders?.map((item) => (
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                      }}
                      key={item.id}
                      value={item.id}
                      control={<Radio color="success" />}
                      label={item.name}
                    />
                  ))}
                </RadioGroup>
              </div>
            )}
          />
          <FormInput label="Description" {...register("description")} helperText={errors.description?.message} multiline size="medium" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton label="Save" fullWidth disabled={!isValid} onClick={handleSubmit(onSave)} />
      </DialogActions>
    </Dialog>
  );
}

export default EventAddEditForm;
