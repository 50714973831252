import { Container } from "@mui/material";
import React from "react";
import { useDocumentTitle } from "suregifts-component-lib";

function PrivacyScreen() {
  useDocumentTitle("Privacy ANd COOKIES Policy".toUpperCase());
  return (
    <Container
      sx={{
        marginTop: "40px",
        "& > ul > li": {
          marginBottom: "40px",
        },
      }}
    >
      <h2 style={{ textTransform: "uppercase" }}>Privacy ANd COOKIES Policy </h2>

      <p>Last updated: [03/04/2023]</p>

      <p>
        Surebids Limited ("we," "us," or "our") values your privacy and is committed to protecting your personal information. This Privacy Policy applies to www.suregifts.com.ng (the "Site") and describes the types of information we collect, how we
        use it, and with whom we share it. By using the Site, you agree to the terms of this Privacy Policy.
      </p>
      <ul style={{ listStyleType: "number" }}>
        <li>
          Information We Collect
          <p>We collect personal information from you when you use our Site or interact with us, such as when you:</p>
          <ul>
            <li>Create an account</li>
            <li>Purchase or send a gift card, voucher, or other gift-related items</li>
            <li>Subscribe to our newsletter or marketing communications</li>
            <li>Participate in a survey or promotion</li>
            <li>Contact us with questions or feedback</li>
          </ul>
          <p>The types of personal information we collect may include:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Mailing address</li>
            <li>Billing information</li>
            <li>Recipient's name, email address, and phone number (when sending a gift)</li>
            <li>Any other information you choose to provide</li>
          </ul>
        </li>
        <li>
          How We Use Your Information
          <p>We use the personal information we collect for various purposes, including to:</p>
          <ul>
            <li>Process and fulfill your transactions</li>
            <li>Provide customer support and respond to your inquiries</li>
            <li>Send you newsletters, promotions, and other marketing communications</li>
            <li>Improve our Site and services</li>
            <li>Conduct research and analysis</li>
            <li>Comply with legal requirements and protect our legal rights</li>
          </ul>
        </li>
        <li>
          Sharing Your Information
          <p>We do not sell or rent your personal information to third parties. We may share your personal information with:</p>
          <ul>
            <li>Service providers who perform functions on our behalf, such as payment processing, order fulfillment, data analysis, email delivery, and customer support</li>
            <li>Partners who offer products or services through our Site</li>
            <li>
              Authorities when required by law or in response to legal processes, such as subpoenas or court orders We may also share aggregated or de-identified information, which cannot reasonably be used to identify you, with third parties for
              marketing, research, or other purposes.
            </li>
          </ul>
        </li>
        <li>
          Security We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it against unauthorized access, disclosure, alteration, or destruction. However, no method of
          transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.
        </li>
        <li>
          Third-Party Websites Our Site may contain links to other websites operated by third parties. We are not responsible for the privacy practices or the content of those websites. We encourage you to review the privacy policies of any
          third-party websites you visit.
        </li>
        <li>
          Children's Privacy Our Site is not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that a child under 18 has provided us with personal information, we
          will take steps to delete that information.
        </li>
        <li>
          International Data Transfers Our Site is intended for users in Nigeria, and we store and process your personal information in Nigeria. If you are accessing our Site from outside Nigeria, please be aware that your information may be
          transferred to, stored, and processed in Nigeria, where our servers and databases are located.
        </li>
        <li>
          Changes to This Privacy Policy We may update this Privacy Policy from time to time. We will post any changes on our Site and update the "Last updated" date at the top of this Privacy Policy. Your continued use of the Site after any changes
          to this Privacy Policy constitutes your acceptance of the revised Privacy Policy. We encourage you to periodically review this Privacy Policy to stay informed about our privacy practices.
        </li>
        <li>
          Your Rights and Choices Depending on your location and applicable law, you may have certain rights regarding your personal information, such as:
          <ul>
            <li>The right to access, correct, or delete your personal information </li>
            <li>The right to restrict or object to the processing of your personal information </li>
            <li>
              The right to withdraw your consent to the processing of your personal information, where applicable To exercise any of these rights or if you have any questions or concerns about this Privacy Policy, please contact us using the contact
              information provided below.
            </li>
          </ul>
        </li>
      </ul>

      <p>Contact Us If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:</p>

      <p>
        Surebids Limited
        <br />
        Nigeria
        <br />
        Email: hello@suregifts.com.ng
      </p>

      <p>By using our Site, you consent to our collection, use, and disclosure of your personal information as described in this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use our Site.</p>
    </Container>
  );
}

export default PrivacyScreen;
