import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { GreetingCardItem, useGetGreetingCards } from "../../../services/gift";
import { useNavigate } from "react-router-dom";
import { StorageKeys } from "../../../enums";
import { AddItemModel } from "../../../services/cart";
import CartPersonalizeScreen from "./CartPersonalizeScreen";
import { useDocumentTitle } from "suregifts-component-lib";

type CartPersonalizeContextProps = {
  model: AddItemModel;
  updateModel: (params: {}) => void;
};

const CartPersonalizeContext = createContext({} as CartPersonalizeContextProps);

export const useCartPersonalizeContext = () => useContext(CartPersonalizeContext);

export const CartPersonalizeContextProvider = ({}) => {
  const navigate = useNavigate();
  useDocumentTitle("Cart");
  useEffect(() => {
    var data = sessionStorage.getItem(StorageKeys.GIFT_PERSONALIZE);

    if (!data) {
      navigate("/gift-cards");
      return;
    }

    var result = JSON.parse(data) as AddItemModel;

    if (!result.giftId || result.amount <= 0) {
      navigate("/gift-cards");
    }
  }, []);

  const [model, setModel] = useState<AddItemModel>(() => {
    var data = sessionStorage.getItem(StorageKeys.GIFT_PERSONALIZE);
    if (!data) {
      return {} as AddItemModel;
    }

    var result = JSON.parse(data) as AddItemModel;

    if (!result.message) {
      result.message = "A little something for you on your Special Day. Cheers!";
    }
    return result;
  });

  return (
    <CartPersonalizeContext.Provider
      value={{
        model,
        updateModel: (params: any) => {
          setModel((prev) => {
            var result = { ...prev, ...params };
            sessionStorage.setItem(StorageKeys.GIFT_PERSONALIZE, JSON.stringify(result));
            return result;
          });
        },
      }}
    >
      <CartPersonalizeScreen />
    </CartPersonalizeContext.Provider>
  );
};
