import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DialogTitle, FormButton, FormInput, FormSelect, YupShape, useAuthContext } from "suregifts-component-lib";
import { ChangePhoneModel, ProfileInfo, RegisterModel, useChangePhone, useGetPhoneCountries } from "../services/auth";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

const schema = yup
  .object<YupShape<ChangePhoneModel>>({
    phone: yup.string().required(),
    phoneCountryCode: yup.string().required(),
  })
  .required();
function AddPhoneNumberModal() {
  const { user, updateInfo } = useAuthContext();
  const { mutate: changePhone, isLoading } = useChangePhone();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };
  const { data: countryNames } = useGetPhoneCountries();

  useEffect(() => {
    const localUser = user as ProfileInfo;
    const phoneIsSet = localUser?.phoneNumber && localUser?.countryCode;
    if (phoneIsSet) {
      if (localUser.phoneNumberConfirmed) {
        navigate("..");
      } else {
        navigate("/verify-phone");
      }
    }
  }, [user]);

  const countries = useMemo(() => {
    return countryNames?.map((x) => ({
      id: x.isoCode,
      name: x.name,
    }));
  }, [countryNames]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<ChangePhoneModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "",
      phoneCountryCode: "",
    },
  });

  const submitForm = (model) => {
    changePhone(model, {
      onSuccess: (data) => {
        updateInfo(data);
      },
    });
  };
  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title="Add Phone" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <Controller control={control} name="phoneCountryCode" render={({ field }) => <FormSelect required helperText={errors.phoneCountryCode?.message} {...field} label={"Country"} options={countries} />} />
          <FormInput isNumber value={watch("phone")} required label="Phone" {...register("phone")} helperText={errors.phone?.message} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton loading={isLoading} disabled={!isValid} label="Add Phone" onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default AddPhoneNumberModal;
