import {
  Count,
  PageFilter,
  ServerError,
  TransactionModel,
  TransactionStatusType,
  TransactionType,
  WalletInfoData,
  WalletType,
  useAxios,
} from "suregifts-component-lib";

import { useMutation, useQuery } from "react-query";

export const useGetWalletInfo = () => {
  const axios = useAxios();
  return useQuery<WalletInfoData, ServerError>({
    queryKey: ["wallet-info"],
    keepPreviousData: true,
    queryFn: () => axios.get("/transactions/api/account-info", {}),
  });
};

export const useGetBankAccounts = () => {
  const axios = useAxios();
  return useQuery<BankAccountData[], ServerError>({
    queryKey: ["bank-accounts"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/transactions/api/bank-accounts", {}),
  });
};

export const useGetFundingMethods = () => {
  const axios = useAxios();
  return useQuery<FundingMethodData[], ServerError>({
    queryKey: ["funding-methods"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/transactions/api/funding-methods", {}),
  });
};
export const useGetVoucherPaymentMethods = () => {
  const axios = useAxios();
  return useQuery<FundingMethodData[], ServerError>({
    queryKey: ["voucher-methods"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/transactions/api/voucher-methods", {}),
  });
};
export const useFundWithTransfer = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, BankTransferModel>((model) => {
    return axios.post("/transactions/api/fund-bank-transfer", model);
  });
};
export const useFundWallet = () => {
  var axios = useAxios();
  return useMutation<FundingResponse, ServerError, FundingModel>((model) => {
    return axios.post("/transactions/api/fund-wallet", model);
  });
};
export const useVerify = () => {
  var axios = useAxios(false);
  return useMutation<VerifyPaymentResult, ServerError, VerifyFundingModel>((model) => {
    return axios.post("/transactions/api/verify", model);
  });
};
export const useConfirmBankTransfer = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, ConfirmBankTransferModel>((model) => {
    return axios.post("/transactions/api/add-bank-transfer-info", model);
  });
};
export const useGetTransactions = (params: PageFilter) => {
  const axios = useAxios();
  return useQuery<Count<TransactionData>, ServerError>({
    queryKey: ["transactions", params],
    keepPreviousData: true,
    initialData: { total: 0, items: [] },
    queryFn: () =>
      axios.get("/transactions/api", {
        params,
      }),
  });
};
export const useExportPayments = (filter: PageFilter) => {
  var axios = useAxios();
  return useMutation(() => {
    return axios.get(`/transactions/api/export`, {
      params: filter,
    });
  });
};
export interface VerifyPaymentResult {
  statusId: TransactionStatusType;
  status: string;
  typeId: TransactionType;
  type: string;
  methodId: WalletType;
  method: string;
  orderId?: number;
}

export interface FundingResponse {
  paymentLink: string;
  invoiceNumber: string;
}

export interface TransactionData {
  transactionMode: TransactionModel;
  transactionId: string;
  amount: string;
  narration: string;
  date: Date;
  statusId: TransactionStatusType;
  status: string;
  transactionTypeId: TransactionType;
  transactionType: string;
  extras: any;
  newBalance: string;
  previousBalance: string;
}

export interface BankAccountData {
  accountNumber: string;
  bankName: string;
}

export interface FundingMethodData {
  id: WalletType;
  name: string;
}
export interface BankTransferModel {
  depositorName: string;
  referenceNumber: string;
  date: string;
  accountUsed: string;
  amount: number;
}
export interface FundingModel {
  returnPath: string;
  amount: string;
  paymentMethod: number;
}
export interface VerifyFundingModel {
  invoiceNumber: string;
  providerRef: string;
  paymentMethod: number;
}
export interface ConfirmBankTransferModel {
  invoiceNumber: string;
  referenceNumber: string;
  date: string;
  accountUsed: string;
  depositorName: string;
  amount: string;
}
