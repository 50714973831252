import { useEffect, useMemo } from "react";
import { useGetGreetingCards, GreetingCardItem } from "../../../services/gift";
import { useCartPersonalizeContext } from "./CartPersonalizeContext";

export function usePersonalizeImages() {
  const { model, updateModel } = useCartPersonalizeContext();
  const { data: greetingCards } = useGetGreetingCards((data) => {
    if (!model.greetingCardCategoryId) {
      updateModel({ greetingCardCategoryId: data[0].name });
    }
  });
  useEffect(() => {
    if (model.bgPicture) return;
    if (!model.greetingCardCategoryId || !greetingCards) return;

    var category = greetingCards!.filter((x) => x.name === model.greetingCardCategoryId)[0];
    updateModel({ greetingCardId: category.greetingCards[0].id, bgPicture: category.greetingCards[0].picture });
  }, [model.greetingCardCategoryId]);
  const cards = useMemo(() => {
    let holder: GreetingCardItem[] = [];

    if (!model.greetingCardCategoryId) return holder;

    var category = greetingCards!.filter((x) => x.name === model.greetingCardCategoryId);
    if (category.length <= 0) return holder;

    return category[0].greetingCards;
  }, [model.greetingCardCategoryId, greetingCards]);
  const cardCartegories = useMemo(() => {
    if (!greetingCards) {
      return [];
    }

    return greetingCards.map((x) => ({ text: x.name, value: x.name }));
  }, [greetingCards]);
  return { cards, cardCartegories };
}
