import React from "react";
import { Box, Container } from "@mui/material";
import { FormButton, useDocumentTitle } from "suregifts-component-lib";
import { useNavigate } from "react-router-dom";
import { SectionOne } from "./SectionOne";
import StaticHeader from "../../../components/atoms/StaticHeader";
import StaticHeaderInfo from "../../../components/atoms/StaticHeaderInfo";
import { HowItWorkSection } from "../../home/HowItWorkSection";

function MerchantScreen() {
  useDocumentTitle("Become a Merchant");
  return (
    <Container>
      <SectionOne />
      <HowItWorkSection />
      <div style={{ textAlign: "center", marginTop: "5rem" }}>
        <StaticHeader>We are Global, with over 100,000 users</StaticHeader>
        <StaticHeaderInfo>We serve and provide services to amazing users all over the world</StaticHeaderInfo>
      </div>
      <div style={{ padding: "7rem 1rem" }}>
        <Box component={"img"} src="/assets/images/world-map.png" sx={{ width: "100%" }} />
      </div>
    </Container>
  );
}

export default MerchantScreen;
