import { Stack } from "@mui/material";

import { BillsPaymentMethodView } from "./PaymentMethodView";
import { AmountSummaryCard } from "suregifts-component-lib";

export const PaymentSection = () => {
  return (
    <Stack spacing={3}>
      <AmountSummaryCard />
      <BillsPaymentMethodView />
    </Stack>
  );
};
