import { useNavigate } from "react-router-dom";
import { SelectPopover, UserStatusViewV2, useAuthContext } from "suregifts-component-lib";
import { useLogout } from "../../services/auth";
const options: any = [
  { text: "My Account", to: "/account" },
  { text: "Order History", to: "/orders" },
  { text: "My Vouchers", to: "/vouchers" },
  {
    text: "Logout",
    color: "error",
  },
];
export const LoggedInUserView = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthContext();
  const { mutate: logout } = useLogout();
  return (
    <SelectPopover
      onChange={(index, value) => {
        if (index <= 2) {
          navigate(options[index].to);
        } else {
          logout(undefined, {
            onSuccess: () => {
              signOut();
            },
          });
          navigate("/");
        }
      }}
      options={options}
      render={(handleClick) => <UserStatusViewV2 onClick={handleClick} />}
    />
  );
};
