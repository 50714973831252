import { Container, Typography } from "@mui/material";
import { useDocumentTitle } from "suregifts-component-lib";
const data = [
  {
    title: "1. Acceptance of Terms.",
    description:
      "Welcome to suregifts.com.ng, a website owned by Surebids Limited (“Surebids”). Surebids provides its service to you subject to the following Terms of Service (“Terms”). By accessing a Surebids website (each, a “Site”) on the Internet or World Wide Web, you are agreeing to comply with and be bound by the Terms. If you do not wish to agree to the Terms, do not access or use any part of any Site. In addition, when using particular Surebids services, you are subject to any posted guidelines or rules applicable to such services or products. All such guidelines and rules applicable to such services or products are hereby incorporated by reference into the Terms. We may refuse any or all of our services to anyone at any time, in our sole discretion. This site is intended for use by individuals who are at least 13 years of age. If your parent or guardian has any concerns, please feel free to contact us via email at hello@suregifts.com.ng",
  },
  {
    title: "2. Products and Services. ",
    description:
      "Various products such as gift cards, whether they are physical or electronic, may be selected and purchased on a Site (“Surebids Products”). Some Surebids Products can be customized with text, pictures and/or graphics. When you have completed and paid for your order, Surebids will produce the Surebids Products you have selected and send them to the recipients specified by you. The pricing for Surebids Products, which is stated in Nigerian Naira, is set forth on the applicable Site, and incorporated into these Terms by reference. Please note that prices and fees may change from time to time; the price charged to you will be the price set forth on the Site at the time you place your order. Please place and complete your order carefully. All purchases of Surebids Products are non-returnable and non-refundable. If you have any questions or problems with your order or the order has not been received within the expected time frame, please contact us immediately at hello@suregifts.com.ng or 080-9118-9900. Please include your order number in all communications for prompt service.",
  },
];
function TermsAndConditionScreen() {
  useDocumentTitle("Terms and Condition");
  return (
    <Container sx={{ marginTop: "6rem" }}>
      <Typography sx={{ fontSize: "3.375rem", fontWeight: 500, lineHeight: "4.875rem" }}>TERMS AND CONDITIONS</Typography>
      <Typography sx={{ fontSize: "0.725rem", fontWeight: 500, lineHeight: "1rem", marginTop: "0.625rem", marginBottom: "3.75rem" }}>
        THIS SITE AND RELATED SERVICES ARE PROVIDED SUBJECT TO YOUR COMPLIANCE WITH THE TERMS AND CONDITIONS SET FORTH BELOW. PLEASE READ THE FOLLOWING INFORMATION CAREFULLY. <br />
        YOUR CONTINUED USE OF THIS SITE WILL INDICATE YOUR AGREEMENT TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH BELOW. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS, PROMPTLY EXIT THIS SITE.
      </Typography>
      {data.map((item, i) => (
        <div style={{ marginBottom: "3.75rem" }} key={`${item.title}-${i}`}>
          <Typography sx={{ fontSize: "1.875rem", lineHeight: "2.3125rem" }}>{item.title}</Typography>
          <Typography sx={{ lineHeight: "1.5rem", marginTop: "1.25rem" }}>{item.description}</Typography>
        </div>
      ))}
    </Container>
  );
}

export default TermsAndConditionScreen;
