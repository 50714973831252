import { Typography } from "@mui/material";
import { useAccountContext } from "./AccountContext";

export const StatusLabel = () => {
  const { profile } = useAccountContext();
  return (
    <Typography
      component={"span"}
      sx={{
        backgroundColor: `${profile?.profileInfo.isVerified ? "success.main" : "error.main"}`,
        color: `${profile?.profileInfo.isVerified ? "success.contrastText" : "error.contrastText"}`,
        padding: "0.5rem 6px",
        fontSize: "10px",
        textTransform: "uppercase",
        lineHeight: "12px",
        borderRadius: "0.5rem",
        fontWeight: 500,
      }}
    >
      {profile?.profileInfo.isVerified ? "Verified" : "Not Verified"}
    </Typography>
  );
};
