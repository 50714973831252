import { useMutation, useQuery } from "react-query";
import { useAxios, ServerError, useAuthContext, ProfileData, getCaptchaToken } from "suregifts-component-lib";

export const useLogout = () => {
  var axios = useAxios();
  return useMutation<any, ServerError, any>(async (_) => {
    return axios.post("/user-security/api/logout");
  });
};
export const useLogin = () => {
  var axios = useAxios();
  return useMutation<TokenResponse, ServerError, LoginModel>(async (model) => {
    return axios.post("/user-security/api/Login", model, {
      headers: { Captcha: await getCaptchaToken() },
    });
  });
};
export const useRegister = () => {
  var axios = useAxios();
  return useMutation<TokenResponse, ServerError, RegisterModel>(async (model) => {
    return axios.post("/user-security/api/Register", model, { headers: { Captcha: await getCaptchaToken() } });
  });
};
export const useForgotPassword = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, RegisterModel>(async (model) => {
    return axios.post("/user-security/api/forgot-password", model, {
      headers: { Captcha: await getCaptchaToken() },
    });
  });
};
export const useResetPassword = () => {
  var axios = useAxios();
  return useMutation<TokenResponse, ServerError, RegisterModel>((model) => {
    return axios.post("/user-security/api/reset-password", model);
  });
};
export const useGetProfile = () => {
  const axios = useAxios();
  const { updateInfo, user } = useAuthContext();
  return useQuery<ProfileRoot, ServerError>({
    queryKey: ["profile"],
    keepPreviousData: true,
    queryFn: () => axios.get("/user-security/api/GetProfile", {}),
    onSuccess: (data) => updateInfo({ ...user, ...data.profileInfo }),
  });
};
export const useGetPhoneCountries = () => {
  const axios = useAxios();
  return useQuery<CountryNameData[], ServerError>({
    queryKey: ["phone-countries"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/user-security/api/GetPhoneCountries", {}),
  });
};
export const useUpdateProfile = () => {
  var axios = useAxios();
  return useMutation<ProfileInfo, ServerError, UpdateProfileModel>((model) => {
    return axios.post("/user-security/api/UpdateProfile", model);
  });
};
export const useChangePhone = () => {
  var axios = useAxios();
  return useMutation<ProfileInfo, ServerError, ChangePhoneModel>((model) => {
    return axios.post("/user-security/api/change-phonenumber", model);
  });
};
export const useVerifyPhone = () => {
  var axios = useAxios();
  return useMutation<TokenResponse, ServerError, VerifyModel>((model) => {
    return axios.post("/user-security/api/VerifyPhone", model);
  });
};
export const useVerifyEmail = () => {
  var axios = useAxios();
  return useMutation<TokenResponse, ServerError, VerifyModel>((model) => {
    return axios.post("/user-security/api/VerifyEmail", model);
  });
};
export const useConfirmEmail = () => {
  var axios = useAxios();
  return useMutation<TokenResponse, ServerError, ConfirmEmailModel>((model) => {
    return axios.post("/user-security/api/confirm-email", model);
  });
};

export const useSendVerifyPhoneCode = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>((model) => {
    return axios.post("/user-security/api/SendPhoneVerificationOtp", model);
  });
};
export const useSendVerifyEmailCode = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>(async (model) => {
    return axios.post("/user-security/api/SendVerifyEmailCode", model, {});
  });
};
export const useChangePin = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, ChangePinModel>(async (model) => {
    return axios.post("/user-security/api/ChangePin", model, { headers: { Captcha: await getCaptchaToken() } });
  });
};
export const useCreatePin = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, CreatePinModel>(async (model) => {
    return axios.post("/user-security/api/CreatePin", model, {});
  });
};
export const useResetPin = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>((model) => {
    return axios.post("/user-security/api/ResetPin", model);
  });
};
export const useChangePassword = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, ChangePasswordModel>((model) => {
    return axios.post("/user-security/api/ChangePassword", model);
  });
};
export const useSavePicture = () => {
  var axios = useAxios();
  return useMutation<ProfileInfo, ServerError, SavePictureModel>((model) => {
    return axios.post("/user-security/api/photo", model);
  });
};
export const useRemovePicture = () => {
  var axios = useAxios();
  return useMutation<ProfileInfo, ServerError, unknown>((model) => {
    return axios.delete("/user-security/api/photo");
  });
};

export interface ChangePasswordModel {
  currentPassword: string;
  newPassword: string;
  confirmPassword: String;
}
export interface CreatePinModel {
  pin: string;
  confirmPin: string;
}
export interface ChangePinModel {
  currentPin: string;
  newPin: string;
  confirmPin: string;
}
export interface SavePictureModel {
  imagePath: string;
}

export interface TokenResponse extends ProfileInfo {
  refreshToken: string;
  token: string;
  referrerCode: string;
  hasDiscount: boolean;
}
export interface VerifyResponse {
  accessToken: string;
}
export interface ForgotPasswordModel {
  returnPath: string;
  email: string;
}
export interface VerifyModel {
  otpCode: string;
}
export interface ResetPasswordModel {
  email?: string | null;
  password: string;
  confirmPassword: string;
  resetToken?: string | null;
}

export interface LoginModel {
  email: string;
  password: string;
}
export interface UpdateProfileModel {
  firstName: string;
  lastName: string;
  phone: string;
  phoneCountryCode: string;
  receiveSmsLetter: boolean;
  receiveEmailLetter: boolean;
}
export interface ConfirmEmailModel {
  email: string;
  confirmToken: string;
}

export interface RegisterModel {
  email: string;
  password: string;
  confirmPassword: String;
  firstName: string;
  lastName: string;
  receiveNewLetter: boolean;
  acceptTerms: boolean;
  phone: string;
  phoneCountryCode: string;
  referrerCode: string;
}
export interface ProfileRoot {
  contacts: number;
  totalOrders: number;
  vouchers: number;
  walletBalance: number;
  profileInfo: ProfileInfo;
}
export interface CountryNameData {
  name: string;
  dailCode: string;
  isoCode: string;
}

export interface ProfileInfo extends ProfileData {
  hasPin: boolean;
  hasPassword: boolean;
  phoneNumberConfirmed: boolean;
  emailConfirmed: boolean;
  receiveSmsLetter: boolean;
  receiveEmailLetter: boolean;
  isVerified: boolean;
  externalLogins: string[];
  referralCode: string;
  hasDiscount: boolean;
}
export interface ChangePhoneModel {
  phone: string;
  phoneCountryCode: string;
}
