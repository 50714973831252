import { Box, Stack, Typography } from "@mui/material";
import SuregiftsLogo from "../../components/atoms/SuregiftsLogo";
import Card from "@mui/material/Card";
import { FacebookIconSm, InstagramIconSm, TwitterIconSm } from "suregifts-component-lib";

export const SocialSection = () => {
  return (
    <Card
      sx={{
        padding: "1.875rem 2.5rem",
        bgcolor: "primary.main",
        borderRadius: "1.875rem",
        display: "flex",
        gap: "1.25rem",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "center",
        border: "none",
      }}
    >
      <SuregiftsLogo />
      <Stack
        spacing={"3.125rem"}
        direction="row"
        sx={{
          alignItems: "center",
          "& a": {
            color: "inherit",
          },
        }}
      >
        <Typography sx={{ fontSize: "1.125rem", fontWeight: 500 }}>Social</Typography>
        <Typography fontSize={"1.25rem"}>-</Typography>
        <a href="https://www.facebook.com/suregiftsNG">
          <FacebookIconSm />
        </a>
        <a href="https://www.instagram.com/suregiftsng/">
          <InstagramIconSm />
        </a>
        <a href="https://twitter.com/suregifts">
          <TwitterIconSm />
        </a>
      </Stack>
    </Card>
  );
};
