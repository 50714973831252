import { Box, Button, Hidden, Stack, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import moment from "moment";
import { OrderInfoDto } from "../../../services/order";
import { useNavigate } from "react-router-dom";
import { ConfirmBankTransferView } from "../../cart/completed/resultViews/ConfirmBankTransferModal";
import { useState } from "react";
type RecipientsSectionProps = {
  data?: OrderInfoDto;
  changeIndex: (index: number) => void;
  index?: number;
};
export const RecipientsSection = ({ data, changeIndex, index }: RecipientsSectionProps) => {
  if (!data) {
    return <div></div>;
  }
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Typography sx={{ fontSize: "24px", fontWeight: 700, lineHeight: "30px" }}>Receipients</Typography>
        <Stack sx={{ marginTop: "30px" }} spacing="2px">
          {data.recipients.map((item, i) => (
            <Box
              key={item.id}
              sx={{
                borderRadius: "20px",
                padding: "20px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "space-between",
                ...(index === i && { backgroundColor: "primary.light" }),
                cursor: "pointer",
              }}
              onClick={() => changeIndex(i)}
            >
              <DisplayTemplate title={`${item.firstName} ${item.lastName}`} details={`ID - #${item.id}`} />
              <DisplayTemplate title={item.value} details={moment(item.dateCreated).fromNow()} />
            </Box>
          ))}
        </Stack>
      </Box>
    </>
  );
};
type DisplayTemplateProps = {
  title: String;
  details?: string;
  align?: "left" | "right";
};
const DisplayTemplate = ({ title, details, align = "left" }: DisplayTemplateProps) => {
  return (
    <Stack spacing={"6px"} sx={{ textAlign: align }}>
      <Typography sx={{ fontWeight: 500, lineHeight: "20px" }}>{title}</Typography>
      <Typography sx={{ fontSize: "12px", fontWeight: 500, lineHeight: "15px", color: "text.secondary" }}>{details}</Typography>
    </Stack>
  );
};
