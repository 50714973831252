import { Box, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";

function TopBanner() {
  const theme = useTheme();
  return (
    <Card
      sx={{
        display: "none",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url(/assets/images/gift-cards-topbg.png)",
        backgroundSize: "cover",
        padding: "120px 0 108px",
        borderRadius: "24px",
        [theme.breakpoints.up("md")]: {
          display: "flex",
        },
      }}
    >
      <Typography sx={{ fontSize: "45px", fontWeight: 700, lineHeight: "110%" }}>Select a Gift Card to Get Started</Typography>
      <Typography sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "30px" }}>Gifts makes it easy for you to give the perfect gift catd</Typography>
    </Card>
  );
}

export default TopBanner;
