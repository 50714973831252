import { useMutation, useQuery } from "react-query";
import { useAxios, ServerError, NameAndId, PageFilter, Count } from "suregifts-component-lib";

export const useMerchantRegister = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, MerchantModel>((model) => {
    return axios.post("/merchant/api/applications", model);
  });
};
export const useGetStates = (countryId: any) => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["store-states", { countryId }],
    keepPreviousData: true,
    initialData: [],
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!countryId,
    queryFn: () =>
      axios.get("/merchant/api/store-states", {
        params: { countryId },
      }),
  });
};

export const useGetStoreLocations = (params: StoreLocatorFilter) => {
  const axios = useAxios();
  return useQuery<Count<StoreLocatorData>, ServerError>({
    queryKey: ["store-locations", params],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: () => axios.get("/merchant/api/store-locations", { params }),
  });
};
export const useGetSwapMerchant = (giftId) => {
  const axios = useAxios();
  return useQuery<SwapMerchantName[], ServerError>({
    queryKey: ["swap-merchants", { giftId }],

    initialData: [],
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!giftId,
    queryFn: () => axios.get(`/merchant/api/swap-merchants`, { params: { giftId } }),
  });
};
export interface SwapMerchantName {
  id: string;
  name: string;
  swapRate: number;
}

export interface StoreLocatorData {
  id: number;
  name: string;
  address: string;
  email: string;
  phone: string;
  storeCount: number;
  stores: StoreLocatorData[];
}

export interface StoreLocatorFilter extends PageFilter {
  countryId: any;
  stateId: any;
}
export interface MerchantModel {
  name: string;
  rcNumber: string;
  email: string;
  contactName: string;
  address: string;
  phone: string;
  industryId: string;
}
