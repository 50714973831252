import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { VerifyPaymentResult, useVerify } from "../../services/transaction";
import { FailedCheckoutView } from "../cart/completed/resultViews/FailedCheckoutView";
import { SuccessCheckoutView } from "../cart/completed/resultViews/SuccessCheckoutView";
import { useRetrievePaymentData } from "../../hooks/useRetrievePaymentData";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingBox, TransactionStatusType } from "suregifts-component-lib";
import { EmptyPageLayout } from "../cart/completed/CartCompletedScreen";

function PaymentCallbackScreen() {
  const { invoiceNumber, paymentMethod, providerRef } = useRetrievePaymentData();
  const [data, setData] = useState<VerifyPaymentResult | undefined>();
  const { mutate: verifyPayment } = useVerify();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.indexOf("/payment_callback") === -1) {
      navigate("/");
      return;
    }

    if ((!invoiceNumber && !providerRef) || !paymentMethod) {
      navigate("/");
      return;
    }
    const paymentModel = { invoiceNumber, providerRef, paymentMethod };
    verifyPayment(paymentModel, {
      onSuccess: (result) => {
        if (!data) {
          setData(result);
        }
      },
    });
  }, []);

  if (!data) return <LoadingBox loading loadingPosition="center" />;

  return (
    <EmptyPageLayout>
      <Box
        sx={{
          backgroundColor: "primary.dark",
          position: "absolute",
          display: "flex",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {data.statusId === TransactionStatusType.Successful ? <SuccessCheckoutView orderId={data.orderId!} /> : <FailedCheckoutView orderId={data.orderId!} />}
      </Box>
    </EmptyPageLayout>
  );
}

export default PaymentCallbackScreen;
