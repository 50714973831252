import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

type LinkLinkViewProps = {
  title: string;
  links: LinkProps[];
};
type LinkProps = {
  to?: string;
  href?: string;
  title: string;
};
export const LinkLinkView = ({ title, links }: LinkLinkViewProps) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Heading label={title} />
      <Box
        component={"ul"}
        sx={{
          listStyle: "none",
          margin: 0,
          padding: 0,
          fontWeight: 400,
          "& a": {
            fontSize: "1rem",
            textDecoration: "none",
            color: "text.primary",
            padding: "0.625rem 0 ",
            display: "inline-block",
          },
        }}
      >
        {links.map((item) => (
          <li key={item.title}>
            {item.to ? (
              <Link to={item.to}>{item.title}</Link>
            ) : (
              <a href={item.href || "#"} target="_blank">
                {item.title}
              </a>
            )}
          </li>
        ))}
      </Box>
    </Box>
  );
};
export const Heading = ({ label }: { label: string }) => {
  return <Typography sx={{ fontSize: "0.75rem", color: "text.secondary", textTransform: "uppercase", fontWeight: 500, marginBottom: "2.5rem" }}>{label}</Typography>;
};
