import { Box, useTheme } from "@mui/material";
import { LinkLinkView } from "./LinkLinkView";
import { NewsLetterSection } from "./NewsLetterSection";
import { useGlobalContext } from "../../contexts/GlobalContext";

function ExtraLinkSection() {
  const theme = useTheme();
  const { initialSetting } = useGlobalContext();
  return (
    <Box
      sx={{
        display: "grid",
        gap: "1.875rem",
        marginY: "3.75rem",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "2fr 1fr",
          whiteSpace: "nowrap",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "2.5rem",
        }}
      >
        <LinkLinkView
          title={"Products"}
          links={[
            { title: "Redeem Gift Cards", to: "/how-to-use" },
            { title: "Become a Merchant", to: "/merchant" },
            { title: "Corporate Gifting", href: `${initialSetting?.businessUrl}` },
          ]}
        />
        <LinkLinkView
          title={"Support"}
          links={[
            { title: "Contact Us", to: "/contact-support" },

            { title: "FAQs", to: "/faq" },
            { title: "Merchant Locations", to: "/locations" },
            { title: "Terms and Conditions", to: "/terms-and-condition" },
          ]}
        />
        <LinkLinkView
          title={"Company"}
          links={[
            { title: "Careers", href: "https://suregifts.breezy.hr/" },
            { title: "Blog", href: "https://suregifts.medium.com/" },
          ]}
        />
      </Box>
      <NewsLetterSection />
    </Box>
  );
}
export default ExtraLinkSection;
