import { Box, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormButton, FormInput, PageHeaderTitle, YupShape } from "suregifts-component-lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useAccountSettingContext } from "./AccountSettingContext";
import { CreatePinModel, useCreatePin } from "../../../services/auth";
import { useQueryClient } from "react-query";
const schema = yup
  .object<YupShape<CreatePinModel>>({
    pin: yup.string().required().length(4),
    confirmPin: yup.string().required().length(4),
  })
  .required();
export const CreatePinSection = () => {
  const queryClient = useQueryClient();
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors, isValid },
    watch,
  } = useForm<CreatePinModel>({
    defaultValues: {
      confirmPin: "",
      pin: "",
    },
    resolver: yupResolver(schema),
  });
  const { mutate: createPin, isLoading } = useCreatePin();
  const submitForm = (model) => {
    createPin(model, {
      onSuccess: () => {
        toast.success("Pin created successfully.");
        queryClient.invalidateQueries("profile");
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <PageHeaderTitle title="Create Pin" />
      <Box style={{ fontSize: "14px", fontStyle: "italic" }}>Pin must be 4 digits</Box>
      <Stack spacing={3} sx={{ paddingTop: "30px" }}>
        <Stack spacing={2} direction="row">
          <FormInput required label="New Pin" type="password" isNumber charLength={4} value={watch("pin")} {...register("pin")} helperText={errors.pin?.message} />
          <FormInput required label="Confirm Pin" isNumber type="password" charLength={4} value={watch("confirmPin")} {...register("confirmPin")} helperText={errors.confirmPin?.message} />
        </Stack>
        <FormButton label="Submit" loading={isLoading} disabled={!isValid} type="submit" sx={{ alignSelf: "flex-start" }} />
      </Stack>
    </form>
  );
};
