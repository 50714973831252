import { Box, Container, Typography } from "@mui/material";

import { useGlobalContext } from "../../../contexts/GlobalContext";

export const TopLayoutSection = () => {
  const { initialSetting } = useGlobalContext();
  return (
    <div style={{ height: "25rem", boxSizing: "border-box", backgroundImage: "url(/assets/images/faq-bg.png)", backgroundSize: "cover", position: "absolute", top: "0", left: "0", width: "100%", zIndex: -1 }}>
      <Container>
        <Box maxWidth={"sm"} sx={{ marginTop: "10rem" }}>
          <Typography sx={{ fontSize: "2.75rem", fontWeight: 500, lineHeight: "130%" }}>Customer Care</Typography>
          <Typography sx={{ fontSize: "1.125rem", lineHeight: "1.875rem" }}>If you can't find the answer to what you're looking for, feel free to contact us directly {initialSetting?.contactEmail}</Typography>
        </Box>
      </Container>
    </div>
  );
};
