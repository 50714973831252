import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { FormButton, FormInput, PageHeaderTitle, YupShape, useDocumentTitle, useNotificationContext } from "suregifts-component-lib";
import { ReleaseVoucherModel, useReleaseVoucher } from "../../../../services/voucher";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { VoucherInfoView } from "../VoucherInfoView";
import VoucherServiceCard from "../VoucherServiceCard";
import { useEffect } from "react";
const schema = yup
  .object<YupShape<ReleaseVoucherModel>>({
    voucherCode: yup.string().required(),
    pin: yup.string().required().length(4),
  })
  .required();

function VoucherReleaseScreen() {
  const [searchParms, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useDocumentTitle("Release Voucher");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<ReleaseVoucherModel>({
    resolver: yupResolver(schema),
    defaultValues: { pin: "", voucherCode: searchParms.get("voucherCode") || "" },
  });
  const { isLoading, mutate: release, data } = useReleaseVoucher();
  const submitForm = (model: ReleaseVoucherModel) => {
    release(model, {
      onSuccess: () => {
        toast.success("Voucher released successfully.");
        navigate(`/vouchers`);
      },
    });
  };

  useEffect(() => {
    setSearchParams({ voucherCode: watch("voucherCode") });
  }, [watch("voucherCode")]);

  return (
    <div style={{ display: "grid", gap: "30px" }}>
      <VoucherInfoView />
      <VoucherServiceCard title="Release Voucher">
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={3}>
            <FormInput required label="Voucher Code" {...register("voucherCode")} helperText={errors.voucherCode?.message} />
            <FormInput required label="Create Pin" type="password" isNumber charLength={4} {...register("pin")} helperText={errors.pin?.message} value={watch("pin")} />
          </Stack>
          <FormButton label="Release" loading={isLoading} disabled={!isValid} onClick={handleSubmit(submitForm)} sx={{ alignSelf: "flex-start" }} />
        </Stack>
      </VoucherServiceCard>
    </div>
  );
}

export default VoucherReleaseScreen;
