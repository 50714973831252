import { Dialog, DialogContent, Stack } from "@mui/material";
import React from "react";
import { DialogTitle, TitleDatailInlineV2, TransactionStatusLabel, TransactionStatusType } from "suregifts-component-lib";
import { useWalletContext } from "./WalletContext";

function TransactionViewDialog() {
  const { state, dispatch } = useWalletContext();
  const handleClose = () => {
    dispatch({ type: "Main" });
  };
  const item = state.transaction;
  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle title={"Transaction Details"} handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={3}>
          <TitleDatailInlineV2 dt={"Status"} dd={<TransactionStatusLabel status={item?.statusId} text={item?.status} />} />
          <TitleDatailInlineV2 dt={"Transaction Type"} dd={item?.transactionType} />
          <TitleDatailInlineV2 dt={"Amount"} dd={item?.amount} />
          {item?.newBalance && <TitleDatailInlineV2 dt={"Wallet Balance"} dd={item?.newBalance} />}
          {item?.previousBalance && <TitleDatailInlineV2 dt={"Previous Balance"} dd={item?.previousBalance} />}
          {Object.keys(item!.extras).map((x) => (
            <TitleDatailInlineV2 dt={x} dd={item?.extras[x]} />
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default TransactionViewDialog;
