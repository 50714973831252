import { useNavigate } from "react-router-dom";
import { FormButton, WalletType } from "suregifts-component-lib";
import { useCheckout } from "../../../services/cart";
import { Modal, Box } from "@mui/material";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { useCartContext } from "../checkout/CartContext";
import CheckoutResultModal from "../completed/resultViews/CheckoutResultView";
import { toast } from "react-toastify";
import { StorageKeys } from "../../../enums";

export const CheckoutButtons = () => {
  const navigate = useNavigate();
  const { mutate: checkout, isLoading } = useCheckout();
  const { model, calcAmount } = useCartContext();
  const { cart } = useGlobalContext();

  return (
    <>
      <FormButton label="Add Another Gift" color="primary" onClick={() => navigate("/gift-cards")} />
      <FormButton
        disabled={cart?.items.length === 0}
        label="Complete Order"
        loading={isLoading}
        onClick={() => {
          if (calcAmount.outstanding > 0 && model.paymentMethod === WalletType.None) {
            toast.warning("Please select a payment method.");
            return;
          }
          checkout(model, {
            onSuccess: (data) => {
              sessionStorage.removeItem(StorageKeys.GIFT_PERSONALIZE);
              sessionStorage.setItem(StorageKeys.CHECKOUT_RESULT, JSON.stringify(data));
              navigate("/cart/completed");
            },
          });
        }}
      />
    </>
  );
};
