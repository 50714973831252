import { Box, useTheme, Typography, Stack } from "@mui/material";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TitleDetailView, FilterDropdown, VoucherStatusType, LoadingBox } from "suregifts-component-lib";
import { VoucherInfoDto, useConvertVoucher } from "../../../../services/voucher";
import { defaultStoreImage } from "../VoucherCard";
import { VoucherStatusLabel } from "../../../../components/atoms/VoucherStatusLabel";
import { OperationView } from "./OperationView";
import { useMemo } from "react";
import { toast } from "react-toastify";

type VoucherInfoViewProps = {
  data: VoucherInfoDto;
};

export const VoucherInfoView = ({ data }: VoucherInfoViewProps) => {
  const navigate = useNavigate();
  const { mutate: converVoucher, isLoading } = useConvertVoucher();
  const localLinks = useMemo(() => {
    const mainLinks = [
      { text: "Split", to: "/voucher-services/split" },
      { text: "Merge", to: "/voucher-services/merge" },
      { text: "Swap", to: "/voucher-services/swap" },
      { text: "Release", to: "/voucher-services/release" },
      { text: "Transfer", to: "/voucher-services/transfer" },
    ];
    if (data.isLegacy) {
      mainLinks.push({ text: "Convert" } as any);
    }
    return data.isExpired ? [] : mainLinks;
  }, [data]);
  return (
    <LoadingBox
      loading={isLoading}
      sx={{
        gap: "40px",
        display: "grid",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "grid",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "240px 1fr",
          },
          gap: "40px",
          flexWrap: "wrap",
          postion: "relative",
        })}
      >
        {data.statusId === VoucherStatusType.Active ? (
          <div style={{ position: "absolute", top: "10px", right: "30px" }}>
            <FilterDropdown
              onChange={(index, value) => {
                if (index === 5) {
                  converVoucher(data.voucherCode, {
                    onSuccess: (result) => {
                      navigate(`/vouchers/${result.voucherCode}`);
                      toast.success("Voucher converted successfully.");
                    },
                  });
                } else {
                  navigate(`${localLinks[index].to}?voucherCode=${data.voucherCode}`);
                }
              }}
              display="Action"
              options={localLinks}
            />
          </div>
        ) : null}
        <Box sx={{ height: "140px", width: "240px", borderRadius: "18px", objectFit: "cover" }} component="img" src={data.imageUrl ?? defaultStoreImage} />
        <Stack spacing={"30px"} sx={{}}>
          <Stack spacing={"20px"} direction={"row"}>
            <TitleDetailView title="Voucher code" detail={data.voucherCode} />
            <TitleDetailView
              title="Expire date"
              detail={
                <Typography fontSize="inherit" color={data.isExpired ? "error.main" : "inherit"}>
                  {moment(data.expiryDate).format("DD.MM.YYYY")}
                </Typography>
              }
            />
          </Stack>
          <Stack spacing={"20px"} direction={"row"}>
            <TitleDetailView title="Merchant " detail={data.merchantName} />
            <TitleDetailView title="Serial No" detail={data.serialNumber} />
          </Stack>
          <Stack spacing={"20px"} direction={"row"}>
            <TitleDetailView title="Starting Balance" detail={data.value} />
            <TitleDetailView title="Current Balance" detail={data.balance} />
          </Stack>
          <Stack spacing={"20px"} direction={"row"}>
            <TitleDetailView title="Countries" detail={data.countries} />
            <TitleDetailView title="Status" detail={<VoucherStatusLabel text={data.status} status={data.statusId} />} />
          </Stack>
        </Stack>
      </Box>
      {data.operations.length > 0 && <OperationView operations={data.operations} />}
    </LoadingBox>
  );
};
