import { SectionOne } from "./SectionOne";
import { HowItWorkSection } from "./HowItWorkSection";
import { SectionThree } from "./SectionThree";
import SectionFour from "./SectionFour";
import { SectionFive } from "./SectionFive";
import { FeaturedVocuhersSection } from "./FeaturedVocuhersSection";
import { Container } from "@mui/material";
import { useDocumentTitle } from "suregifts-component-lib";
import { Outlet } from "react-router-dom";

function HomeScreen() {
  useDocumentTitle("Home Page");
  return (
    <Container>
      <SectionOne />
      <HowItWorkSection />
      <FeaturedVocuhersSection />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <Outlet />
    </Container>
  );
}

export default HomeScreen;
