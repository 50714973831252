import { Container, SxProps, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { SideBarSection } from "./SideBarSection";
import { Carousel } from "./Carousel";
import { useCartPersonalizeContext } from "./CartPersonalizeContext";
import { CartJourneySection } from "../CartJourneySection/CartJourneySection";
import { usePersonalizeImages } from "./usePersonalizeImages";

import { UserPreferenceSection } from "./UserPreferenceSection";
function CartPersonalizeScreen() {
  const theme = useTheme();
  const { model } = useCartPersonalizeContext();
  const { cardCartegories, cards } = usePersonalizeImages();
  return (
    <Container sx={{ marginTop: "40px" }}>
      <Box
        sx={{
          display: "grid",
          gap: "20px",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "250px 1fr",
          },
        }}
      >
        <SideBarSection categories={cardCartegories} />
        <div style={{ display: "grid", gap: "40px", position: "relative" }}>
          <Carousel cards={cards}></Carousel>
          <UserPreferenceSection />
        </div>
      </Box>
      <CartJourneySection activeStep={0} model={model} />
    </Container>
  );
}

export default CartPersonalizeScreen;
