import { Typography, Box, useTheme } from "@mui/material";

export const SectionThree = () => {
  const theme = useTheme();
  return (
    <div style={{ textAlign: "center", marginTop: "10rem" }}>
      <Typography
        sx={{
          fontSize: {
            xs: "1.5625rem",
            md: "2.8125rem",
          },
          fontWeight: 700,
        }}
      >
        Gift Cards from over <span style={{ color: theme.palette.warning.main }}> 200 </span>
        merchants in Nigeria
      </Typography>
      <Box component={"img"} src="/assets/images/bg/3.png" sx={{ width: "100%", marginTop: "5rem" }} />
    </div>
  );
};
