import { SuccessCheckoutView } from "./SuccessCheckoutView";
import { FailedCheckoutView } from "./FailedCheckoutView";
import { BankTransferView } from "./BankTransferView";
import { ThirdPrtyPayentResultView } from "./ThirdPrtyPayentResultView";
import { TransactionStatusType, WalletType } from "suregifts-component-lib";
import { CheckoutResult } from "../../../../services/cart";

type OrderResultModalProps = {
  data: CheckoutResult;
};

function CheckoutResultModal({ data }: OrderResultModalProps) {
  if (data.paymentStatusId === TransactionStatusType.Failed) return <FailedCheckoutView orderId={data.orderId} />;
  if (!data.outstanding) return <SuccessCheckoutView orderId={data.orderId} />;
  if (data.paymentMethodId === WalletType.BankTransfer) return <BankTransferView data={data} />;
  return <ThirdPrtyPayentResultView data={data} />;
}

export default CheckoutResultModal;
