import { Typography } from "@mui/material";
import { formatMoney } from "suregifts-component-lib";
import { useGetGiftCardById } from "../../../../services/gift";
import { useCartDeliveryContext } from "../useCartDeliveryContext";
const CodeAmountView = ({ title, desc }) => {
  return (
    <div>
      <Typography sx={{ fontSize: "14px" }}>{title}</Typography>
      <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>{desc}</Typography>
    </div>
  );
};
export const RightBottom = () => {
  const { model } = useCartDeliveryContext();
  const { data: gift } = useGetGiftCardById(model?.giftId);
  return (
    <div style={{ background: "linear-gradient(180deg, #c8c8c9 -61.3%, #f5f5f5 52.8%)", padding: "74px 50px 45px", display: "flex", flexDirection: "column", gap: "20px" }}>
      <img
        src={gift?.imageUrl}
        style={{
          display: "block",
          width: "100%",
          objectFit: "cover",
          maxHeight: "180px",
          marginInline: "auto",
          borderRadius: "1rem",
        }}
      />
      <div style={{ display: "flex", gap: "1rem", justifyContent: "space-between" }}>
        <CodeAmountView title={"Amount"} desc={formatMoney(model.amount, gift?.currency)} />
        <CodeAmountView title={"Voucher Code"} desc={"**********"} />
      </div>
      <div>
        <img src="/assets/images/barcode.png" alt="" />
        <Typography sx={{ fontSize: "14px", marginTop: "7px" }}>Serial no: 1235643456545</Typography>
      </div>
      <Typography sx={{ fontSize: "14px", fontWeight: 500, fontStyle: "italic" }}>Valid from 10/02/2021 - 10/02/2022</Typography>
    </div>
  );
};
