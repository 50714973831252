import { Container } from "@mui/material";
import React from "react";
import { ErrorPageTemplate } from "suregifts-component-lib";
import { CookieNoticeView } from "./CookieNoticeView";
import FooterSection from "./footer/FooterSection";
import { GlobalContextProvider } from "../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <GlobalContextProvider>
      <Container sx={{ marginBottom: "60px", marginTop: "2.5rem" }}>
        <ErrorPageTemplate
          imageUrl="/assets/images/not-found.svg"
          button1Label={"Go Back"}
          button2Label={"Contact Us"}
          topText={"Ooops! Page not found"}
          button1Click={() => window.history.back()}
          button2Click={() => navigate("/contact-support")}
          bottomText={
            <span>
              We can't find what you're looking for because the page you're <br />
              looking for doesn't exist.
            </span>
          }
        />
      </Container>
      <FooterSection />
    </GlobalContextProvider>
  );
}

export default NotFoundPage;
