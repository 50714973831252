import { Typography } from "@mui/material";
import React from "react";
type ContactCardProps = {
  title: string;
  desc: string;
  multiline?: boolean;
};
export function ContactTtitleDescView({ title, desc, multiline = false }) {
  return (
    <>
      <Typography sx={{ color: "text.secondary", fontSize: "0.75rem", fontWeight: "500", lineHeight: "1rem", textTransform: "uppercase", marginTop: "1.25rem" }}>{title}</Typography>
      <Typography
        sx={{
          whiteSpace: "nowrap",
          ...(multiline && {
            height: "3rem",
            whiteSpace: "normal",
          }),
          fontSize: "0.875rem",
          lineHeight: "1.5rem",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {desc}
      </Typography>
    </>
  );
}

export default ContactTtitleDescView;
