import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { DatePicker, DialogTitle, FormButton, FormInput, FormSelect, TitleDatailInlineV2, YupShape } from "suregifts-component-lib";
import { useWalletContext } from "./WalletContext";
import { Stack } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { BankTransferModel, useGetBankAccounts, useFundWithTransfer } from "../../../services/transaction";

const schema = yup
  .object<YupShape<BankTransferModel>>({
    amount: yup.number().required(),
    accountUsed: yup.string().required(),
    depositorName: yup.string().required(),
    date: yup.date().required(),
    referenceNumber: yup.string().required(),
  })
  .required();
function BankDepositModal() {
  const { dispatch, state } = useWalletContext();
  const { data: accounts } = useGetBankAccounts();
  const { mutate: fundAccount, isLoading } = useFundWithTransfer();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<BankTransferModel>({
    defaultValues: {
      accountUsed: "",
      date: moment().toISOString(),
    },
    resolver: yupResolver(schema),
  });
  const handleClose = () => {
    dispatch({ type: "Main" });
  };
  const submitForm = (data) => {
    fundAccount(data, {
      onSuccess: () => {
        toast.success("Transfer details uploaded successfully.");
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth="sm" scroll="body">
      <DialogTitle title="Bank Deposit" handleClose={handleClose} />
      <DialogContent>
        <Typography sx={{ color: "#AAAABE" }}>To make a payment, you can directly transfer funds to one of our bank accounts. Please choose one of the following options.</Typography>
        <Stack spacing={2} sx={{ marginTop: "1.875rem" }}>
          {accounts?.map((item) => (
            <TitleDatailInlineV2 key={item.accountNumber} dt={item.bankName} dd={item.accountNumber} />
          ))}
        </Stack>
        <Stack spacing={2} sx={{ mt: "2.5rem" }}>
          <FormInput label="Depositor's Name" {...register("depositorName")} helperText={errors.depositorName?.message} />
          <Stack direction={"row"} spacing={2}>
            <Controller control={control} name="date" render={({ field: { value, onChange } }) => <DatePicker label={"Date"} value={value} onChange={onChange} helperText={errors.date?.message} />} />
            <FormInput label="Amount" {...register("amount")} helperText={errors.amount?.message} />
          </Stack>
          <FormInput label="Reference Number" {...register("referenceNumber")} helperText={errors.referenceNumber?.message} />
          <Controller control={control} name="accountUsed" render={({ field }) => <FormSelect options={accounts?.map((x) => ({ name: x.bankName, id: x.accountNumber }))} label="Account Used" {...field} helperText={errors.accountUsed?.message} />} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton label="Upload" loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default BankDepositModal;
