import { Box, SxProps, Typography } from "@mui/material";
import { CheckoutResult } from "../../../../services/cart";
import { usePaymentMethodImage } from "../../../profile/wallet/BalanceCard";
import { FormButton, SuccessTickIcon, WalletType } from "suregifts-component-lib";
import { Link } from "react-router-dom";
import { useMemo } from "react";
const rootStyle: SxProps = {
  width: "100%",
  background: "rgba(0, 0, 0, 0.8)",
  padding: "76px 60px",
  borderRadius: "25px",
  backdropFilter: "blur(2.5rem)",
  borderTop: "0.0625rem solid #676767",
  borderLeft: "0.125rem solid #676767",
  borderBottom: "0.125rem solid #676767",
  borderRight: "0.125rem solid #676767",
  maxWidth: "477px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "20px",
  textAlign: "center",
  justifyItems: "center",
  alignItems: "center",
};
export const ThirdPrtyPayentResultView = ({ data }: { data: CheckoutResult }) => {
  const image = usePaymentMethodImage(data.paymentMethodId);

  return (
    <Box sx={rootStyle}>
      <div style={{ display: "flex", gap: "20px", justifyContent: "center", alignItems: "center" }}>
        <Box component={"img"} src={image} />
        <Typography sx={{ fontSize: "30px", fontWeight: 700, lineHeight: "130%" }}>{data.paymentMethod}</Typography>
      </div>
      <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>You're about to pay</Typography>
      {data.paymentMethodId === WalletType.Paypal && <Typography sx={{ fontSize: "14px" }}>Note: Orders will be delivered after PayPal payment has been verified. If payment is not verifired, a refund will be processed</Typography>}
      <Typography
        sx={{
          bgcolor: "primary.light",
          borderRadius: "20px",
          padding: "13px 40px",
          fontSize: "30px",
          fontWeight: 600,
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {data.outstanding}
        <SuccessTickIcon sx={{ color: "success.main", width: "30px", height: "30px" }} />
      </Typography>
      <Typography sx={{ fontWeight: "18px" }}>You wll be redirected to our payment processing platform</Typography>

      <FormButton
        label="Make Payment"
        sx={{ alignSelf: "stretch" }}
        onClick={() => {
          window.location.href = data.paymentLink;
        }}
      />
      <Box sx={{ color: "#C4C4C4" }} color="inherit" component={Link} to="/account">
        Cancel
      </Box>
    </Box>
  );
};
