import React from "react";
import { Outlet } from "react-router-dom";
import { useContactContext } from "./ContactContext";
import EventAddEditForm from "./add-edit/EventAddEditModal";

function ContactLanding() {
  const { dispatch, state } = useContactContext();
  return (
    <>
      <Outlet />
    </>
  );
}

export default ContactLanding;
