import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { GreetingCardItem } from "../../../services/gift";
import { GreetingCardImageView } from "./GreetingCardImageView";
import { useCartPersonalizeContext } from "./CartPersonalizeContext";
type CarouselProps = {
  cards?: GreetingCardItem[];
};

export const Carousel = ({ cards }: CarouselProps) => {
  const { updateModel, model } = useCartPersonalizeContext();
  return (
    <Box
      sx={{
        position: "relative",
        "& .MuiIconButton-root": {
          position: "absolute",
          top: "26px",
          backgroundColor: "primary.contrastText",
          zIndex: 1000000,
        },
      }}
    >
      <div style={{ width: "100%" }}>
        <div>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {cards?.map((item) => (
              <GreetingCardImageView
                onClick={() => {
                  updateModel({ bgPicture: item.picture, greetingCardId: item.id });
                }}
                deletable={false}
                key={item.id}
                url={item.picture}
                selected={item.picture === model.bgPicture}
              />
            ))}
          </Box>
        </div>
      </div>
    </Box>
  );
};
