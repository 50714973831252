import { Button } from "@mui/material";

export const ProfileTopButton = ({ label, onClick }) => {
  return (
    <Button
      color="primary"
      sx={{
        padding: "1rem 36px",
        borderRadius: "18px",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "17px",
        "&.MuiButtonBase-root.MuiButton-root": {
          backgroundColor: "primary.dark",
        },
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
