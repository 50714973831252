import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { TopLayoutSection } from "./TopLayoutSection";
import MessageSection from "./MessageSection";
import { useDocumentTitle } from "suregifts-component-lib";

function FaqScreen() {
  useDocumentTitle("FAQ");
  return (
    <Box>
      <TopLayoutSection />
      <MessageSection />
    </Box>
  );
}

export default FaqScreen;
