import { DialogContent, Typography } from "@mui/material";
import { DialogTitle, FormButton, FormInput, YupShape } from "suregifts-component-lib";
import { useGlobalContext } from "../contexts/GlobalContext";
import { Stack } from "@mui/system";
import { ForgotPasswordModel, useForgotPassword } from "../services/auth";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { AuthDialog } from "../components/atoms/AuthDialog";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object<YupShape<ForgotPasswordModel>>({
    email: yup.string().required().email(),
  })
  .required();
function ForgotPassword() {
  const handleClose = () => {
    navigate("/");
  };
  const navigate = useNavigate();
  const { mutate: forgotPassword, isLoading } = useForgotPassword();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      returnPath: "reset-password",
    },
  });
  const submitForm = (model) => {
    forgotPassword(model, {
      onSuccess: (data) => {
        toast.success("Reset link sent you to your email.");
        handleClose();
      },
    });
  };
  return (
    <AuthDialog open maxWidth="xs">
      <DialogTitle title="Forgot Password?" handleClose={handleClose} />
      <DialogContent>
        <form onSubmit={handleSubmit(submitForm)}>
          <Stack spacing={3}>
            <Typography sx={{ fontWeight: 500, lineHeight: "25px" }}>Not to worry! Enter the email address for your account and we'll send you a reset link.</Typography>
            <FormInput required label="Email" {...register("email")} helperText={errors.email?.message} />
          </Stack>

          <FormButton loading={isLoading} type="submit" label="Send" fullWidth disabled={!isValid} />
        </form>
      </DialogContent>
    </AuthDialog>
  );
}
export default ForgotPassword;
