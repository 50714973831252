import { Stack, Typography, Box } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { SuccessTickIcon, FormButton } from "suregifts-component-lib";

type SuccessViewProps = {
  orderId: number;
};
export const SuccessCheckoutView = ({ orderId }: SuccessViewProps) => {
  const navigate = useNavigate();
  return (
    <Stack sx={{ maxWidth: "430px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} spacing={3}>
      <SuccessTickIcon sx={{ width: 80, height: 80, color: "success.main" }} />
      <Typography sx={{ fontSize: "30px", fontWeight: 700, lineHeight: "40px" }}>Order Successful</Typography>
      <Typography sx={{ fontSize: "18px" }}>
        Your order{" "}
        <Typography component={"span"} sx={{ color: "success.main" }}>
          #{orderId}
        </Typography>{" "}
        has been successfully placed. You will get an email confirmation shortly
      </Typography>
      <FormButton
        label="View Order"
        sx={{ width: "300px" }}
        onClick={() => {
          navigate(`/orders/${orderId}`);
        }}
      />
      <Box component={Link} to="/account" sx={{ color: "inherit", textDecoration: "none" }}>
        Go to Dashboard
      </Box>
    </Stack>
  );
};
