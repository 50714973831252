import { Stack, Typography, Box } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { SuccessTickIcon, FormButton } from "suregifts-component-lib";
import { CheckoutResult } from "../../../../services/cart";
import { useGetBankAccounts } from "../../../../services/transaction";
import { useState } from "react";
import { ConfirmBankTransferView } from "./ConfirmBankTransferModal";

type BankTransferViewProps = {
  data: CheckoutResult;
};

export const BankTransferView = ({ data: result }: BankTransferViewProps) => {
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();
  const closeModal = () => setConfirm(false);
  return (
    <>
      <BankTransferSuccessView result={result} setConfirm={() => setConfirm(true)} />
      {confirm && (
        <ConfirmBankTransferView
          onSubmit={() => {
            closeModal();
            navigate(`/orders/${result.orderId}`, { replace: true });
          }}
          invoiceNumber={result.invoiceNumber}
          handleClose={closeModal}
        />
      )}
    </>
  );
};
const BankTransferSuccessView = ({ result, setConfirm }: { result: CheckoutResult; setConfirm: () => void }) => {
  return (
    <Stack sx={{ maxWidth: "430px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} spacing={"10px"}>
      <SuccessTickIcon sx={{ width: 80, height: 80, color: "success.main" }} />
      <Typography sx={{ fontSize: "30px", fontWeight: 700, lineHeight: "40px" }}>Order Successful</Typography>
      <Typography sx={{ fontSize: "18px" }}>
        Your order{" "}
        <Typography component={"span"} sx={{ color: "success.main" }}>
          #{result.orderId}
        </Typography>{" "}
        has been successfully placed. You will get an email confirmation shortly
      </Typography>
      <div>
        <BankAccountsView />
        <p style={{ fontSize: "14px" }}>Note: Use your order number as the payment description when making payment. Thanks! 😊</p>
      </div>
      <FormButton
        label="Click here to confirm payment"
        sx={{ width: "300px" }}
        onClick={() => {
          setConfirm();
        }}
      />
      <Box component={Link} to={`/orders/${result.orderId}`} sx={{ color: "inherit", textDecoration: "none", fontSize: "14px" }}>
        Not Yet
      </Box>
    </Stack>
  );
};

const BankAccountsView = () => {
  const { data: accounts } = useGetBankAccounts();
  return (
    <Box
      sx={{
        background: "rgba(0, 0, 0, 0.8)",
        padding: "21px",
        borderRadius: "40px",
        width: "100%",
        backdropFilter: "blur(2.5rem)",
        borderTop: "0.0625rem solid #878787",
        borderLeft: "0.125rem solid #878787",
        borderBottom: "0.125rem solid #676767",
        borderRight: "0.125rem solid #878787",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Typography sx={{ fontSize: "18px" }}>Please make payment to one of our bank accounts listed below</Typography>
      <Stack spacing={"14px"}>
        {accounts?.map((x) => (
          <AccountNumberInfoView key={x.accountNumber} desc={x.accountNumber} title={x.bankName} />
        ))}
      </Stack>
    </Box>
  );
};
const AccountNumberInfoView = ({ title, desc }) => {
  return (
    <div style={{ display: "flex", gap: "30px", justifyContent: "space-between" }}>
      <Typography sx={{ fontWeight: 700 }}>{title}</Typography>
      <Typography sx={{ fontWeight: 500 }}>{desc}</Typography>
    </div>
  );
};
