import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { SearchContainer, PageHeaderTitle, SearchInput, SearchButton, PageFilter, Pagination, useNotificationContext, useFilter } from "suregifts-component-lib";

import { ContactCard } from "./ContactCard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useContactContext } from "../ContactContext";
import EventsModal from "./EventsModal";
import EventAddEditForm from "../add-edit/EventAddEditModal";
import { useTheme } from "@mui/material/styles";
import { useAddEvent, useDeleteContact, useEditEvent, useGetContacts } from "../../../../services/contact";
import Grid from "@mui/material/Unstable_Grid2";
function ContactScreen() {
  const theme = useTheme();
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, size: 12, search: "" });
  const { data, isLoading, refetch } = useGetContacts(filter);
  const { mutate: deleteContact, isLoading: isDeleting } = useDeleteContact();
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { confirm } = useNotificationContext();
  const navigate = useNavigate();
  const { state, dispatch } = useContactContext();
  const { mutate: addEvent, isLoading: isAdding } = useAddEvent(state.contact?.id);
  const { mutate: editEvent, isLoading: isEditing } = useEditEvent(state.contact?.id);
  const handleSaveEvent = (model) => {
    if (state.pageType === "EditEvent") {
      editEvent(model, {
        onSuccess: (data) => {
          toast.success("Event Updated successfully.");
          dispatch({ type: "Events", param: data });
        },
      });
    } else {
      addEvent(model, {
        onSuccess: (data) => {
          toast.success("Event Added successfully.");
          dispatch({ type: "Events", param: data });
        },
      });
    }
  };
  useEffect(() => {
    if (state.pageType === "Main") {
      refetch();
    }
  }, [state.pageType]);

  return (
    <div>
      <SearchContainer
        sx={{
          flexWrap: "wrap",
          gap: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PageHeaderTitle title={"Contacts"} />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput
            placeholder="Search Contact"
            sx={{
              bgcolor: "primary.light",
              borderRadius: "18px",
              minWidth: "175px",
            }}
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e, page: 1 });
            }}
          />

          <SearchButton sx={{ py: "0.125rem" }} label="Add Contact" onClick={() => navigate(`add`)} />
        </Box>
      </SearchContainer>
      <Grid container spacing={3}>
        {data?.items.map((item) => (
          <Grid xs={12} md={4} gap={3}>
            <ContactCard
              key={item.id}
              data={item}
              onEditClick={() => {
                navigate(`edit/${item.id}`);
              }}
              onNotificaitonClick={() => {
                dispatch({ type: "Events", param: item });
              }}
              onDeleteClick={() =>
                confirm({
                  onConfirm: () => {
                    deleteContact(item.id, {
                      onSuccess: () => {
                        toast.success("Contact deleted successfully.");
                        refetch();
                      },
                    });
                  },
                })
              }
            />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: "grid",
          marginTop: "1.875rem",
          rowGap: "50px",
          columnGap: "30px",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2,1fr)",
          },
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(3,1fr)",
          },
        }}
      ></Box>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          align="center"
          size={filter.size}
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
      {state.pageType === "Events" && <EventsModal />}
      {(state.pageType === "AddEvent" || state.pageType === "EditEvent") && (
        <EventAddEditForm
          handleClose={() => {
            dispatch({ type: "Events", param: state.contact! });
          }}
          onSave={handleSaveEvent}
        />
      )}
    </div>
  );
}

export default ContactScreen;
