import { Box } from "@mui/material";
import React from "react";
import { BackView } from "./BackView";
import { FrontView } from "./FrontView";
import Divider from "@mui/material/Divider/Divider";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
function PreviewSection() {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2,285px)",
        gap: "12px",
        marginTop: "60px",
        "& > div": {
          overflow: "hidden",
        },
      }}
    >
      <FrontView />
      <BackView />
      <Divider sx={{ gridColumn: "span 2", marginTop: "24px" }} />
      <div style={{ gridColumn: "span 2", display: "flex", flexDirection: "column", gap: "10px" }}>
        <InfoView text={"This is only a representation of what your card will look like. Actual card design may differ."} />
      </div>
    </Box>
  );
}
export const InfoView = ({ text }) => {
  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "center", fontWeight: 500, fontSize: "12px", lineHeight: "18px" }}>
      <ErrorRoundedIcon color="success" />
      <span>{text}</span>
    </div>
  );
};
export default PreviewSection;
