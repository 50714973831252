import { useState } from "react";
import { PageFilter, WalletType, useFilter } from "suregifts-component-lib";
import { useWalletContext } from "./WalletContext";
import FilterSection from "./FilterSection";
import TableView from "./TableView";
import WalletInfoSection from "./WalletInfoSection";
import FundingModal from "./FundingModal";
import BankDepositModal from "./BankDepositModal";
import TransactionViewDialog from "./TransactionViewDialog";
import usePaymentCallback from "../../../hooks/usePaymentCallback";
import { Card } from "@mui/material";

function WalletScreen() {
  const { state } = useWalletContext();
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "" });
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  return (
    <div>
      <WalletInfoSection />
      <Card sx={{ padding: "40px 50px", marginTop: "20px", borderRadius: "30px", backgroundColor: "primary.main" }}>
        <FilterSection handleFilter={handleFilter} filter={filter} />
        <TableView filter={filter} handleFilter={handleFilter} />
      </Card>
      {state.type === "Fund" && state.paymentMethod?.id !== WalletType.BankTransfer && <FundingModal />}
      {state.type === "Fund" && state.paymentMethod?.id === WalletType.BankTransfer && <BankDepositModal />}
      {state.type === "View" && <TransactionViewDialog />}
    </div>
  );
}

export default WalletScreen;
