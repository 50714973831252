import { Box, Avatar, Stack, Typography } from "@mui/material";
import { useQueryClient } from "react-query";
import { useAuthContext, FileUploader, FormButton } from "suregifts-component-lib";
import { useSavePicture, useRemovePicture } from "../../../services/auth";
import { useAccountSettingContext } from "./AccountSettingContext";

export const ProfilePictureView = () => {
  const { profile: user } = useAccountSettingContext();
  const { mutate: savePicture, isLoading } = useSavePicture();
  const { mutate: removePicture, isLoading: isRemoving } = useRemovePicture();
  const queryClient = useQueryClient();
  return (
    <Box display="flex" alignItems="center" sx={{ overflow: "hidden", position: "relative" }}>
      <Avatar
        sx={{
          borderRadius: "1.125rem",
          width: "110px",
          height: "110px",
          flexGrow: 0,
        }}
        src={user?.photoUrl || "/assets/images/avatar.png"}
      ></Avatar>
      <Box display="flex" flexDirection="column" gap={"12px"} alignItems={"flex-start"} flexGrow={1} marginLeft={"1.0625rem"}>
        <Box sx={{ fontSize: "20px", fontWeight: 700, marginBottom: "0.5rem" }}>Profile Picutre</Box>
        <Stack direction={"row"} spacing="10px">
          <div>
            <FileUploader
              onChange={(e) => {
                savePicture(
                  { imagePath: e as string },
                  {
                    onSuccess: (data) => {
                      queryClient.invalidateQueries("profile");
                    },
                  }
                );
              }}
              renderControl={(uploadControl) => {
                return <FormButton label={user?.photoUrl ? `Upload new picture` : "Upload picture"} onClick={uploadControl.onClick} disabled={isLoading} loading={isLoading} />;
              }}
            />
          </div>

          {user?.photoUrl && (
            <FormButton
              label="Delete"
              color="primary"
              loading={isRemoving}
              onClick={() => {
                removePicture(
                  {},
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries("profile");
                    },
                  }
                );
              }}
            />
          )}
        </Stack>
        <Typography sx={{ fontSize: "10px", fontWeight: "500", color: "text.secondary", textTransform: "uppercase" }}>Maximum size allowed is 500kb of PNG,JPEG.</Typography>
      </Box>
    </Box>
  );
};
