import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";
import StaticHeader from "../../../components/atoms/StaticHeader";
import StaticHeaderInfo from "../../../components/atoms/StaticHeaderInfo";

export const SectionOne = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        marginTop: "5rem",
        display: "grid",
        gap: "5rem",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
      }}
    >
      <div style={{ paddingTop: "2rem" }}>
        <StaticHeader>Merchant Sign-up is Easy as 1, 2, 3 ...</StaticHeader>
        <StaticHeaderInfo>Join over 200 merchants who have expanded their business and reached a wider audience by becoming a part of our merchant network.</StaticHeaderInfo>
        <FormButton
          label="Sign-up as Merchant"
          sx={{ marginTop: "2.5rem", justifyContent: "flex-start", padding: "2rem 10rem 2rem 2rem", borderRadius: "1.25rem", paddingRight: "10rem", fontSize: "1rem", fontWeight: 700 }}
          onClick={() => navigate("/merchant-register")}
        />
      </div>
      <Box component="img" sx={{ width: "100%" }} src="/assets/images/bg/4.png" />
    </Box>
  );
};
