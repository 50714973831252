import { useMemo } from "react";
import { GiftCardFilter, useGetGiftCards } from "../../services/gift";
import { Box, Container, useTheme } from "@mui/material";
import { GiftCardView, LoadingBox, Pagination, useAuthContext, useFilter } from "suregifts-component-lib";
import TopBanner from "./TopBanner";
import FilterSection from "./FilterSection";
import { Outlet, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "suregifts-component-lib";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { ProfileInfo } from "../../services/auth";

function GiftCardsScreen() {
  useDocumentTitle("Gift Cards");
  const { initialSetting } = useGlobalContext();
  var params = useMemo(() => {
    let countryCode: any = undefined;
    if (initialSetting?.countryCode === "NG" || initialSetting?.countryCode === "KE") {
      countryCode = initialSetting.countryCode;
    }
    return { page: 1, countryCode, ...params };
  }, []);

  const [filter, setFilter] = useFilter<GiftCardFilter>(params);
  const { data, isFetching } = useGetGiftCards({ ...filter, size: 24 });
  const navigate = useNavigate();
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const theme = useTheme();
  return (
    <Container sx={{ marginTop: "40px" }}>
      <Outlet />
      <Box>
        <TopBanner />
        <LoadingBox loading={isFetching}>
          <FilterSection handleFilter={handleFilter} filter={filter} />
          <Box
            sx={{
              display: "grid",
              gap: "1.25rem",
              marginTop: "40px",
              justifyContent: "center",
              [theme.breakpoints.up("sm")]: {
                gridTemplateColumns: "repeat(2,1fr)",
              },
              [theme.breakpoints.up("md")]: {
                gridTemplateColumns: "repeat(3,1fr)",
              },
              [theme.breakpoints.up("lg")]: {
                gridTemplateColumns: "repeat(4,1fr)",
              },
              "& > div": {
                maxWidth: "280px",
                justifySelf: "center",
              },
            }}
          >
            {data?.items?.map((item) => (
              <GiftCardView
                key={item.giftId}
                gift={item}
                onClick={() => {
                  navigate(item.giftId);
                }}
              />
            ))}
          </Box>
        </LoadingBox>
        <div style={{ marginTop: "1.25rem" }}>
          <Pagination
            align="center"
            size={24}
            page={filter.page}
            total={data?.total}
            onChange={(page) => {
              handleFilter({ page });
            }}
          />
        </div>
      </Box>
    </Container>
  );
}

export default GiftCardsScreen;
