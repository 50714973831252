export const Socials = () => {
  return (
    <div className="socials">
      <p>©2023 Suregifts. All Rights Reserved.</p>
      <ul>
        <li>
          <a href="#">
            <img src="/assets/images/promo/instagram.png" alt="Instagram" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="/assets/images/promo/facebook.png" alt="Facebook" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="/assets/images/promo/twitter.png" alt="Twitter" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="/assets/images/promo/linkedin.png" alt="Linkedin" />
          </a>
        </li>
      </ul>
    </div>
  );
};
